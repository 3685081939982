import React from "react";
import SeriesEvent from "../../../../types/Types";
import { Link } from "react-router-dom";


const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

interface IProps {

    display : boolean;
    event: SeriesEvent;

}

function ListItemDetail(
    {
        display = false,
        event

    }: IProps
) {

    const startHour = new Date(event.start.replaceAll('.000000Z',''));
    const endHour = new Date(event.end.replaceAll('.000000Z',''));



    function getRightTime(hour: Date) {

        let minutes, fullHour: string;

        if (hour.getMinutes() == 0) {
            minutes = hour.getMinutes() + '0';
        } else {
            minutes = hour.getMinutes().toString();
        }
        if (hour.getHours() < 12) {
            fullHour = hour.getHours() + ':' + minutes + ' AM';
        } else {
            fullHour = hour.getHours()  + ':' + minutes + ' PM';
        }

        return fullHour;
    }


    return (

        <div className={'accordion-visible'} style={{maxHeight: (display)?('100vh'):('0')}}>
            <div className="accordion-show">
                <div className="triple-steps">
                    <div className="section-left equal-height">
                        <img src={event.logo} alt="logo"/>
                    </div>
                    <div className="left-join equal-height">
                        <div className="title">Time:</div>
                        <div className="content">
                            <p>({daysOfWeek[startHour.getDay()]}, {monthNames[startHour.getMonth()]} {startHour.getDate()}, {startHour.getFullYear()} )</p>
                            <p>{getRightTime(startHour)} - {getRightTime(endHour)}</p>
                        </div>
                    </div>
                    <div className="right-join equal-height">
                        <div className="title">Location:</div>
                        <div className="content">
                            <p>{event.location.address}</p>
                        </div>
                    </div>
                </div>
                <div className="map-step">
                    <div className="section-bottom">
                        <div className="left-join">
                            {/*<a href="/wp-admin/admin-ajax.php?action=eventon_ics_download&amp;event_id=1052&amp;ri=0">*/}
                            {/*    Add to Calendar*/}
                            {/*</a>*/}
                            {/* todo: */}
                        </div>
                        <div className="right-join">
                            <Link to={'/event-info/' + event.id} >
                                MORE INFORMATION
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default ListItemDetail;
