import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import ProfileEditView from "../screens/profileEdit/ProfileEditView";
import ProfileView from "../screens/profile/ProfileView";

interface IProps {
    match : any
}

const ProfileNavigationView = ({ match }:IProps)=>{

    return (
        <Switch>

            <Route
                path={`${match.url}/`}
                render={(props) => <ProfileView {...props} />}
                exact={true}
            />
            <Route
                path={`${match.url}/edit`}
                render={(props) => <ProfileEditView {...props} />}
                exact={true}
            />

            <Redirect to={'/profile'} />

        </Switch>
    );
}

export default ProfileNavigationView;
