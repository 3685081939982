import React from "react";
import './ResultsButton.scss';
import YellowArrow from './assets/yellow-arrow.png';
import BlackArrow from './assets/arrow-black.png';
import BluePalellogram from './assets/blue-paralellogram.png';
import RedPalellogram from './assets/red-paralellogram.png';
import GreyParalellogram from './assets/grey-paralellogram.png';
import Upcoming from './assets/time-left.png';
import Calendar from './assets/calendar2.png'
import QuestionMark from './assets/question-mark.png';
import Paralellogram from './assets/rectangle-17.png';
import {NavLink} from "react-router-dom";
import SendResultButton from "../sendResultButton/SendResultButton";
import ResultsInputArea from "../resultsInputArea/ResultsInputArea";
import {SubmitAResult} from "../../utils/api/InnerPageServices";

export type ResultStatusType = 'COMPLETED'|'UPCOMING'|'SUBMIT RESULT'|'WAITING APPROVAL'|'DENIED';

interface IProps {
    eventId : number;
    title: string;
    miles: string;
    date: string;
    hours: string;
    time: string;
    location: string;
    postCode: string;
    raceState: ResultStatusType;
    refreshList : ()=>void;
}

interface IState {
    active: boolean,
    show: boolean,
    className: string;
    value: string;
}

class ResultsButton extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            active: false,
            show: true,
            className: '',
            value: this.props.miles,
        }
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.submitResult = this.submitResult.bind(this);
    }

    submitResult(){
        const component = this;
        SubmitAResult(
            this.props.eventId,
            parseInt(this.state.value)
        ).then(()=>{
            this.toggle();
            component.props.refreshList();
            alert('Thank you. Your miles are being uploaded for approval.');
        }).catch(()=>{
            alert('An error occurred!');
        })
    }

    toggle() {
        this.setState({
            active: !this.state.active,
            show: !this.state.show,
            className: 'active'
        });
    }

    handleChange(e: any) {

        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({value: e.target.value})
        }
    }

    render() {

        const activeClass = this.state.active ? 'active' : 'inactive';

        const cardStyle = {
            opacity: this.state.show ? 0 : 1,
            transition: 'all .3s ease-in',
            height: this.state.show ? '0' : 'auto',
            padding: this.state.show ? '0' : '20px 0'
        }

        return (

            <div className={activeClass + " wrapper"}
            >
                <div className="results-button-top"
                     onClick={this.toggle}
                >
                    <div className="section-one">
                        {this.props.title}
                    </div>
                    <div className="slash">
                        /
                    </div>
                    {(this.props.raceState === 'COMPLETED') ?
                        <div className="section-two"
                             style={{
                                 backgroundImage: `url(${Paralellogram})`,
                                 backgroundRepeat: 'no-repeat',
                                 backgroundPosition: 'center',
                                 backgroundSize: 'cover'
                             }}
                        >
                            {this.props.miles}
                            <span>
                                miles
                            </span>
                        </div> : null
                    }
                    {(this.props.raceState === 'WAITING APPROVAL') ?
                        <div className="section-two"
                             style={{
                                 backgroundImage: `url(${BluePalellogram})`,
                                 backgroundRepeat: 'no-repeat',
                                 backgroundPosition: 'center',
                                 backgroundSize: 'cover'
                             }}
                        >
                            {this.props.miles}
                            <span>
                                miles
                            </span>
                        </div> : null
                    }
                    {(this.props.raceState === 'DENIED') ?
                        <div className="section-two"
                             style={{
                                 backgroundImage: `url(${RedPalellogram})`,
                                 backgroundRepeat: 'no-repeat',
                                 backgroundPosition: 'center',
                                 backgroundSize: 'cover'
                             }}
                        >
                            {this.props.miles}
                            <span>
                                miles
                            </span>
                        </div> : null
                    }
                    {((this.props.raceState === 'UPCOMING')) ?
                        <div className="section-two"
                             style={{
                                 backgroundImage: `url(${GreyParalellogram})`,
                                 backgroundRepeat: 'no-repeat',
                                 backgroundPosition: 'center',
                                 backgroundSize: 'cover'
                             }}
                        >
                            <div className="icon">
                                <img src={Calendar} alt="upcoming"/>
                            </div>
                        </div> : null
                    }
                    {((this.props.raceState === 'SUBMIT RESULT')) ?
                        <div className="section-two"
                             style={{
                                 backgroundImage: `url(${GreyParalellogram})`,
                                 backgroundRepeat: 'no-repeat',
                                 backgroundPosition: 'center',
                                 backgroundSize: 'cover'
                             }}
                        >
                            <div className="icon">
                                <img src={Upcoming} alt="upcoming"/>
                            </div>
                        </div> : null
                    }

                    <div className="slash">
                        /
                    </div>
                    <div className="section-three">
                        {this.props.date}
                    </div>
                    <div className="slash">
                        /
                    </div>
                    <div className="section-four">
                        {this.props.raceState}
                    </div>
                    <div className="section-five">
                        {((this.props.raceState === 'COMPLETED') && (activeClass === 'inactive')) ?
                            <div className="black-arrow">
                                <img src={BlackArrow} alt="black-arrow"/>
                            </div>
                            : null
                        }
                        {((this.props.raceState === 'COMPLETED') && (activeClass === 'active')) ?
                            <div className="yellow-arrow">
                                <img src={YellowArrow} alt="yellow-arrow"/>
                            </div>
                            : null
                        }

                    </div>
                </div>

                <div style={cardStyle} className="results-button-bottom">
                    <div className="left">
                        <div className="top">
                            Time:
                        </div>
                        <div className="middle">
                            {this.props.time}
                        </div>
                        <div className="bottom">
                            {this.props.hours}
                        </div>
                    </div>
                    <div className="middle">
                        <div className="top">
                            Location:
                        </div>
                        <div className="middle">
                            {this.props.location}
                        </div>
                        <div className="bottom">
                            {this.props.postCode}
                        </div>
                    </div>
                    <div className="right">
                        <div>
                            {((this.props.raceState === 'COMPLETED')) ?
                                <NavLink to={'/'} className="more-information">
                                    MORE INFORMATION
                                </NavLink>
                                : null
                            }
                            {(this.props.raceState === 'UPCOMING') ?
                                <NavLink to={'/'} className="more-information">
                                    MORE INFORMATION
                                </NavLink>
                                : null
                            }
                            {(this.props.raceState === 'SUBMIT RESULT') ?
                                <React.Fragment>
                                    <ResultsInputArea children={'miles'} placeholder={''} inputType={'text'}
                                                      pattern={'"[0-9]*"'}
                                                      inputValue={this.state.value}
                                                      onChangeInputValue={this.handleChange}/>
                                    <SendResultButton onClick={this.submitResult}>Submit Miles</SendResultButton>
                                </React.Fragment>

                                : null
                            }
                            {(this.props.raceState === 'WAITING APPROVAL') ?
                                <React.Fragment>
                                    <ResultsInputArea children={'miles'} placeholder={this.props.miles}
                                                      inputType={'text'}
                                                      pattern={"[0-9]*"}
                                                      inputValue={this.state.value}
                                                      onChangeInputValue={this.handleChange}/>
                                    <SendResultButton onClick={this.submitResult}>Update Miles</SendResultButton>
                                </React.Fragment>

                                : null
                            }
                            {(this.props.raceState === 'DENIED') ?
                                <React.Fragment>
                                    <ResultsInputArea children={'miles'} placeholder={this.props.miles}
                                                      inputType={'text'}
                                                      pattern={"[0-9]*"}
                                                      inputValue={this.state.value}
                                                      onChangeInputValue={this.handleChange}/>
                                    <SendResultButton onClick={this.submitResult}>Update Miles</SendResultButton>
                                </React.Fragment>

                                : null
                            }
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}

export default ResultsButton;
