export type LatestBuyTicketStep2Object = {
    inputEmailParticipant : string;
    inputEmailParticipantConfirm: string;
    inputFirstName: string;
    inputLastName: string;
    inputGender: 'Male'|'Female'|'none';
    inputBirthdayDay: string;
    inputBirthdayMonth: string;
    inputBirthdayYear: string;
    inputPhoneNumber: string;
    inputEstimatedRunningTime: string;
    inputTShirtSize: string;
    inputEmergencyName: string;
    inputEmergencyPhone: string;
    inputTerms: boolean;
};
export type LatestBuyTicketStep3Object = {
    paymentName : string;
    paymentLastname : string;
    paymentStreet1 : string;
    paymentZip : string;
    paymentCity : string;
    paymentState : string;
    paymentEmail : string;
    creditCardName : string;
    creditCardNumber : string;
    creditCardCvc : string;
    creditCardExpirationMonth : string;
    creditCardExpirationYear : string;
};

export const EmptyLatestBuyTicketStep2Object : LatestBuyTicketStep2Object = {
    inputEmailParticipant : '',
    inputEmailParticipantConfirm: '',
    inputFirstName: '',
    inputLastName: '',
    inputGender: 'none',
    inputBirthdayDay: 'none',
    inputBirthdayMonth: 'none',
    inputBirthdayYear: 'none',
    inputPhoneNumber: '',
    inputEstimatedRunningTime: 'none',
    inputTShirtSize: 'none',
    inputEmergencyName: '',
    inputEmergencyPhone: '',
    inputTerms: false
};

export const EmptyLatestBuyTicketStep3Object : LatestBuyTicketStep3Object = {
    paymentName : '',
    paymentLastname : '',
    paymentStreet1 : '',
    paymentZip : '',
    paymentCity : '',
    paymentState : '',
    paymentEmail : '',
    creditCardName : '',
    creditCardNumber : '',
    creditCardCvc : '',
    creditCardExpirationMonth : 'none',
    creditCardExpirationYear : 'none',
};

export default function SetLatestBuyTicketStep2Object (object : LatestBuyTicketStep2Object){
    localStorage.setItem('mySeriesRunnerLatestPaymentInfo1',JSON.stringify(object));
    return({
        type: 'SET_LATEST_BUY_TICKET_STEP_2_OBJECT',
        payload: object
    });
}

export function SetLatestBuyTicketStep3Object (object : LatestBuyTicketStep3Object){
    localStorage.setItem('mySeriesRunnerLatestPaymentInfo2',JSON.stringify(object));
    return({
        type: 'SET_LATEST_BUY_TICKET_STEP_3_OBJECT',
        payload: object
    });
}

export function ClearLatestBuyTicketStep2Object(){
    localStorage.removeItem('mySeriesRunnerLatestPaymentInfo1');
    return({
        type: 'SET_LATEST_BUY_TICKET_STEP_2_OBJECT',
        payload: EmptyLatestBuyTicketStep2Object
    });
}
export function ClearLatestBuyTicketStep3Object(){
    localStorage.removeItem('mySeriesRunnerLatestPaymentInfo2');
    return({
        type: 'SET_LATEST_BUY_TICKET_STEP_3_OBJECT',
        payload: EmptyLatestBuyTicketStep2Object
    });
}
