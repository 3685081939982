import {ACCESS_TOKEN, Runner} from "../../utils/ServiceTypes";

export default function SetCurrentUser(user:Runner, token:ACCESS_TOKEN){

    if(user.is_fake === 0){
        localStorage.setItem('mySeriesRunnerAuth',JSON.stringify({
            user : user,
            token : token
        }));
    }else{
        localStorage.removeItem('mySeriesRunnerAuth');
    }
    return({
        type : 'SET_CURRENT_USER',
        payload : {
            user : user,
            token : token
        }
    });

}
export function EmptyCurrentUser(){

    localStorage.removeItem('mySeriesRunnerAuth');

    return({
        type : 'SET_CURRENT_USER',
        payload : {
            user : {
                id: 0,
                name: '',
                surname: '',
                email: '',
                profile : '',
                description : ''
            },
            token : ''
        }
    });

}
