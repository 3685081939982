import {ServiceCall} from "../ServiceCall";
import SetCurrentUser, {EmptyCurrentUser} from "../../redux/auth/actions";
import store, {dispatchWithPromise} from "../../redux";
import {IResponsePostForgotPassword, IResponsePostLogin, IResponsePostRegister} from "../ServiceTypes";

export function RegisterAFakeRunner(){
    return new Promise((resolve,reject)=>{

        ServiceCall('POST','fake-runner/create','').then((response : any)=>{
            const result : IResponsePostRegister = response.data;
            if(
                result &&
                result.runner &&
                result.access_token
            ){
                dispatchWithPromise(
                    SetCurrentUser(
                        result.runner,
                        result.access_token
                    )
                ).then(()=>{
                    resolve({});
                });
            }else{
                reject({
                    message : result.message
                });
            }
        }).catch((error)=>{
            reject({
                message : error.response.data.message
            });
        });

    });
}


export default function RegisterARunner(
    name : string = '',
    surname : string = '',
    email : string = '',
    password : string = '',
    passwordConfirmation : string = ''
){
    return new Promise((resolve,reject)=>{

        ServiceCall('POST','register',{
            email : email,
            password : password,
            password_confirmation : passwordConfirmation,
            name: name,
            surname: surname,
        }).then((response : any)=>{
            const result : IResponsePostRegister = response.data;
            if(
                result &&
                result.runner &&
                result.access_token
            ){
                dispatchWithPromise(
                    SetCurrentUser(
                        result.runner,
                        result.access_token
                    )
                ).then(()=>{
                    resolve({});
                });
            }else{
                if(
                    result &&
                    result.errors &&
                    result.errors.email &&
                    result.errors.email.length > 0
                ){
                    let temp = '';
                    result.errors.email.forEach((item:string)=>{
                        temp += item+' ';
                    });
                    reject({
                        message : temp
                    });
                }else{
                    reject({
                        message : result.message
                    });
                }
            }
        }).catch((error)=>{
            if(
                error.response.data &&
                error.response.data.errors &&
                error.response.data.errors.email &&
                error.response.data.errors.email.length > 0
            ){
                let temp = '';
                error.response.data.errors.email.forEach((item:string)=>{
                    temp += item+' ';
                });
                reject({
                    message : temp
                });
            }else{
                reject({
                    message : error.response.data.message
                });
            }
        });

    });
}

export function LogoutARunner(history:any){
    dispatchWithPromise(
        EmptyCurrentUser()
    ).then(()=>{
        history.push('/login');
    });
}

export function LoginARunner(
    email : string = '',
    password : string = '',
){
    return new Promise((resolve,reject)=>{

        ServiceCall('POST','login',{
            email : email,
            password : password,
        }).then((response : any)=>{
            const result : IResponsePostLogin = response.data;
            if(
                result &&
                result.user &&
                result.access_token
            ){
                dispatchWithPromise(
                    SetCurrentUser(
                        result.user,
                        result.access_token
                    )
                ).then(()=>{
                    resolve({});
                });
            }else{
                reject({
                    message : result.message
                });
            }
        }).catch(()=>{
            reject();
        });

    });
}

export function SendForgotPasswordMail(
    email : string = ''
){
    return new Promise((resolve,reject)=>{

        ServiceCall('POST','forgot-password',{
            email : email,
        }).then((response : any)=>{
            const result : IResponsePostForgotPassword = response.data;
            if(
                result &&
                result.status
            ){
                resolve({
                    message : result.status
                });
            }else{
                reject();
            }
        }).catch(()=>{
            reject();
        });

    });
}

export function SendResetPasswordRequest(
    email : string = '',
    password : string = '',
    passwordConfirmation : string = '',
    token : string = '',
){
    return new Promise((resolve,reject)=>{

        ServiceCall('POST','reset-password',{
            email : email,
            password : password,
            password_confirmation : passwordConfirmation,
            token : token,
        }).then((response : any)=>{
            if(
                response &&
                response.data &&
                response.data.status &&
                response.data.status === 'Your password has been reset!'
            ){
                resolve({
                    message : response.data.status
                });
            }else if(
                response &&
                response.data &&
                response.data.status
            ){
                reject({
                    message : response.data.status
                });
            }else{
                reject({});
            }
        }).catch(()=>{
            reject({});
        });

    });
}
