import React from "react";
import "./EventInfoInside.scss";

import PageHeader from "../../sharedComponents/pageHeader/PageHeader";
import BodyText from "../../sharedComponents/bodyText/BodyText";
import {Event} from "../../utils/ServiceTypes";
import {AddToFavorites, GetEventInfo} from "../../utils/api/InnerPageServices";
import LoadingIndicator from "../loading/LoadingIndicator";
import FavoriteButton from "../favoriteButton/FavoriteButton";
import {ExtractURLReferer} from "../../utils/Parser";
import store, {IReduxPart} from "../../redux";
import {connect, ConnectedProps} from "react-redux";
import { Link } from "react-router-dom";
import {type} from "os";

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function getRightTime(hour: Date) {

    let minutes, fullHour: string;

    if (hour.getMinutes() == 0) {
        minutes = hour.getMinutes() + '0';
    } else {
        minutes = hour.getMinutes().toString();
    }
    if (hour.getHours() < 12) {
        fullHour = hour.getHours() + ':' + minutes + ' AM';
    } else {
        fullHour = hour.getHours() + ':' + minutes + ' PM';
    }

    return fullHour;
}

type IProps = PropsFromRedux & any & {

}

interface IState {
    eventInfo: null | Event;
    isPending: boolean;
}

class EventInfoInside extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            eventInfo: null,
            isPending: true,
        };
        this.getEventInfo = this.getEventInfo.bind(this);
    }

    componentDidMount() {
        ExtractURLReferer().then(()=>{
            try{
                if(typeof store.getState().favorites.waitingAction?.eventId !== 'undefined'){
                    // @ts-ignore
                    AddToFavorites(store.getState().favorites.waitingAction?.eventId).then();
                }
            }catch (e){

            }
        });
        this.getEventInfo();
    }

    getEventInfo() {
        const component = this;
        const eventId = window.location.pathname.split('/')[2];
        GetEventInfo(eventId).then((result) => {
            component.setState({
                eventInfo: result,
                isPending: false
            });
        }).catch(() => {
            component.setState({
                eventInfo: null,
                isPending: false
            });
        });
    }

    render() {

        let buttonArray = []
        if (this.state.eventInfo !== null) {
            if (typeof this.state.eventInfo.buttons_array === 'string') {
                buttonArray = JSON.parse(this.state.eventInfo.buttons_array)
            } else {
                buttonArray = this.state.eventInfo.buttons_array
            }

        }

        const startHour = new Date((this.state.eventInfo !== null) ? (this.state.eventInfo.start.replaceAll('.000000Z','')) : (0));
        const endHour = new Date((this.state.eventInfo !== null) ? (this.state.eventInfo.end.replaceAll('.000000Z','')) : (0));

        return (
            <div className={'events-info-container'}>

                {(this.state.isPending) ? (
                    <LoadingIndicator/>
                ) : (
                    (this.state.eventInfo !== null) ? (
                        <React.Fragment>
                            <div className={'header-field'}>
                                <PageHeader title={this.state.eventInfo.title}/>
                            </div>
                            {(buttonArray.length > 0) ? (
                                <div className={'buttons-field'}>

                                    {buttonArray.map((item: any,index:number) => {
                                        return (
                                            <a className={'main-button transparent-button'} target={'_blank'}
                                               href={item.url} key={index}>{item.text}</a>
                                        )
                                    })}

                                    <Link className={'main-button transparent-button'}
                                       to={'/buy-ticket/'+this.state.eventInfo.id}>Register Now</Link>

                                    <FavoriteButton eventId={this.state.eventInfo.id}/>

                                </div>
                            ) : (
                                null
                            )}

                            <div className={'table-field'}>
                                <div className={'left-logo-field'}>
                                    <img src={this.state.eventInfo.logo} alt="Oakland Logo"/>
                                </div>
                                <div className={'right-info-field'}>
                                    <div className={'time-field'}>
                                        <BodyText isBold>Title:</BodyText>
                                        <BodyText isBold={false}>
                                            <p>
                                                ({daysOfWeek[startHour.getDay()]}, {monthNames[startHour.getMonth()]} {startHour.getDate()}, {startHour.getFullYear()})
                                            </p>
                                            <p>
                                                {getRightTime(startHour)} - {getRightTime(endHour)}

                                            </p>
                                        </BodyText>

                                    </div>
                                    <div className={'location-field'}>
                                        <BodyText isBold>Location:</BodyText>
                                        <BodyText isBold={false}>{this.state.eventInfo.location.address}</BodyText>
                                    </div>
                                </div>
                            </div>
                            <div className={'desc-area'}>
                                <div className={'text-field'}
                                     dangerouslySetInnerHTML={{__html: this.state.eventInfo.description}}>
                                </div>
                                <div className={'video-area'}>
                                    {(this.state.eventInfo.youtube_link !== null) ? (
                                        <iframe width="640" height="390" frameBorder={0} allowFullScreen={true}
                                                src={this.state.eventInfo.youtube_link}>
                                        </iframe>
                                    ) : null}
                                    {(this.state.eventInfo.vimeo_link !== null) ? (
                                        <iframe width="640" height="390" frameBorder={0} allowFullScreen={true}
                                                src={this.state.eventInfo.vimeo_link}>
                                        </iframe>
                                    ) : null}
                                </div>
                            </div>
                        </React.Fragment>
                    ) : (
                        null
                    )
                )}


            </div>
        );
    }
}

function mapStateToProps(state:IReduxPart){
    return{
        favorites : state.favorites
    }
}
const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;


export default connector(EventInfoInside);
