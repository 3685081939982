import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import "./BasicDropzone.scss";

type IProps = {
    onDrop : (files:File[])=>void;
}

function BasicDropzone(props:IProps) {
    const onDrop = useCallback(acceptedFiles => {
        props.onDrop(acceptedFiles);
    }, []);
    const onReject = useCallback((test)=>{
        if(test.length > 1){
            alert('Select only one file');
        }else{
            alert('An error is occurred.')
        }
    },[]);
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({maxFiles:1,onDrop:onDrop, onDropRejected:onReject});

    return (
        <section className="dropzone-container">
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop the file here, or click to select the file.</p>
            </div>
        </section>
    );
}

export default BasicDropzone;
