import axios from 'axios';
import store from "../redux";

export const mainSiteURL = 'https://www.seriesrunner.com';

export function isEmpty(obj:any) {
    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== 'object') return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
        // @ts-ignore
        if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
}

export function getBackendUrl(){
    // if(
    //     window.location.host === 'myseriesrunner.kamermans.org' ||
    //     window.location.host === 'localhost:3000'
    // ){
    //     return 'https://www.upworksociallift.com';
    // }
    return 'https://api.seriesrunner.com';
}

export function getBaseUrl() {
    return getBackendUrl()+'/api/';
}

function getApiUrl(parameters:any) {
    let parametersString = '';
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const prop in parameters) {
        // eslint-disable-next-line no-useless-concat
        parametersString += '&' + `${prop} = ${parameters[prop]}`;
    }

    return parametersString.split(' ').join('').substr(1);
}

interface IHeaders {
    'accept-language': string;
    'Content-Type'?: string;
    Authorization? : string;
    "Access-Control-Allow-Origin"?: string;
}

type TypeServiceCallMethod = 'GET'|'POST'|'DELETE'|'PUT';

export async function ServiceCall(method:TypeServiceCallMethod, route:string, parameters:any) {
    const headers : IHeaders = {
        'accept-language': 'tr',
        'Access-Control-Allow-Origin' : '*'
    };

    if (
        route !== 'login' &&
        route !== 'register' &&
        route !== 'forgot-password'
    ) {
        headers.Authorization = `Bearer ${
            store.getState().auth.token
        }`;
    }

    /* eslint-disable no-undef */
    const axiosInstance = axios.create({
        baseURL: getBaseUrl(),
        headers,
    });
    /* eslint-enable no-undef */

    axiosInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            return Promise.reject({ ...error });
        }
    );

    switch (method) {
        default:
        case 'GET':
        case 'DELETE': {
            /* Get the api url from parameters */
            let apiUrl = route;
            if (isEmpty(parameters)) {
                apiUrl = route;
            } else {
                const parametersString = getApiUrl(parameters);
                apiUrl = `${route}?${parametersString}`;
            }

            if (method === 'GET') {
                return axiosInstance.get(apiUrl);
            }

            return axiosInstance.delete(apiUrl);
        }
        case 'POST':
            return axiosInstance.post(route, parameters);
        case 'PUT':
            return axiosInstance.put(route, parameters);
    }
}
