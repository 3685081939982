import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import UpcomingRaces from "../screens/upcomingRaces/UpcomingRaces";

interface IProps {
    match : any
}

const UpcomingRacesNavigationView = ({ match }:IProps)=>{

    return (
        <Switch>

            <Route
                path={`${match.url}/`}
                render={(props) => <UpcomingRaces {...props} />}
                exact={true}
            />

            <Redirect to={'/profile'} />

        </Switch>
    );
}

export default UpcomingRacesNavigationView;
