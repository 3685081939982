import React from "react";
import "./LoadingIndicatior.scss";

const LoadingIndicator = ()=>{
    return(
        <div className={'loading-indicator'}>
            <div className="lds-ellipsis active" id={'list-of-races-loading-indicator'}>
                <div/><div/><div/><div/>
            </div>
        </div>
    );
}

export default LoadingIndicator;
