import React, {useState} from "react";
import {Event, Price} from "../../../utils/ServiceTypes";
import TextInput from "../../../sharedComponents/input/TextInput";
import SelectInput from "../../../sharedComponents/input/SelectInput";
import {dispatchWithPromise, IReduxPart} from "../../../redux";
import {connect, ConnectedProps} from "react-redux";
import {
    EmptyLatestBuyTicketStep3Object,
    LatestBuyTicketStep2Object,
    LatestBuyTicketStep3Object, SetLatestBuyTicketStep3Object
} from "../../../redux/persisted/actions";
import LoadingIndicator from "../../../sharedComponents/loading/LoadingIndicator";
import {MakePayment} from "../../../utils/api/InnerPageServices";

function validateEmail(email:string) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

let CreditCardExpirationYearValues : {title:string; value:string;}[]= [{title:'-- Select --',value:'none'}];
const CurrentYear = new Date().getFullYear();
for (let ccEYi = 0; ccEYi < 11; ccEYi ++){
    let newValue = (CurrentYear+ccEYi).toString();
    CreditCardExpirationYearValues.push({
        title : newValue,
        value : newValue
    });
}
const USAStates = [
    {title:'Alabama',value:'Alabama'},
    {title:'Alaska',value:'Alaska'},
    {title:'American Samoa',value:'American Samoa'},
    {title:'Arizona',value:'Arizona'},
    {title:'Arkansas',value:'Arkansas'},
    {title:'California',value:'California'},
    {title:'Colorado',value:'Colorado'},
    {title:'Connecticut',value:'Connecticut'},
    {title:'Delaware',value:'Delaware'},
    {title:'District of Columbia',value:'District of Columbia'},
    {title:'Federated States of Micronesia',value:'Federated States of Micronesia'},
    {title:'Florida',value:'Florida'},
    {title:'Georgia',value:'Georgia'},
    {title:'Guam',value:'Guam'},
    {title:'Hawaii',value:'Hawaii'},
    {title:'Idaho',value:'Idaho'},
    {title:'Illinois',value:'Illinois'},
    {title:'Indiana',value:'Indiana'},
    {title:'Iowa',value:'Iowa'},
    {title:'Kansas',value:'Kansas'},
    {title:'Kentucky',value:'Kentucky'},
    {title:'Louisiana',value:'Louisiana'},
    {title:'Maine',value:'Maine'},
    {title:'Marshall Islands',value:'Marshall Islands'},
    {title:'Maryland',value:'Maryland'},
    {title:'Massachusetts',value:'Massachusetts'},
    {title:'Michigan',value:'Michigan'},
    {title:'Minnesota',value:'Minnesota'},
    {title:'Mississippi',value:'Mississippi'},
    {title:'Missouri',value:'Missouri'},
    {title:'Montana',value:'Montana'},
    {title:'Nebraska',value:'Nebraska'},
    {title:'Nevada',value:'Nevada'},
    {title:'New Hampshire',value:'New Hampshire'},
    {title:'New Jersey',value:'New Jersey'},
    {title:'New Mexico',value:'New Mexico'},
    {title:'New York',value:'New York'},
    {title:'North Carolina',value:'North Carolina'},
    {title:'North Dakota',value:'North Dakota'},
    {title:'Northern Mariana Islands',value:'Northern Mariana Islands'},
    {title:'Ohio',value:'Ohio'},
    {title:'Oklahoma',value:'Oklahoma'},
    {title:'Oregon',value:'Oregon'},
    {title:'Palau',value:'Palau'},
    {title:'Pennsylvania',value:'Pennsylvania'},
    {title:'Puerto Rico',value:'Puerto Rico'},
    {title:'Rhode Island',value:'Rhode Island'},
    {title:'South Carolina',value:'South Carolina'},
    {title:'South Dakota',value:'South Dakota'},
    {title:'Tennessee',value:'Tennessee'},
    {title:'Texas',value:'Texas'},
    {title:'Utah',value:'Utah'},
    {title:'Vermont',value:'Vermont'},
    {title:'Virgin Island',value:'Virgin Island'},
    {title:'Virginia',value:'Virginia'},
    {title:'Washington',value:'Washington'},
    {title:'West Virginia',value:'West Virginia'},
    {title:'Wisconsin',value:'Wisconsin'},
    {title:'Wyoming',value:'Wyoming'}
];

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
let birthDayOptions : {title:string;value:string;}[]= [{title:'Day',value:'none'}];
let birthMonthOptions : {title:string;value:string;}[]= [{title:'Month',value:'none'}];
let birthYearOptions : {title:string;value:string;}[]= [{title:'Year',value:'none'}];
for(let i=1; i < 32; i++){
    birthDayOptions.push({title:i.toString(), value: i.toString()})
}
for(let i=0; i < 12; i++){
    birthMonthOptions.push({title:monthNames[i].toString(), value: (i+1).toString()})
}
const yearNow = new Date().getFullYear();
for(let i=0; i < 101; i++){
    birthYearOptions.push({title:(yearNow-i).toString(), value: (yearNow-i).toString()})
}


function getRightTime(hour: Date) {

    let minutes, fullHour: string;

    if (hour.getMinutes() == 0) {
        minutes = hour.getMinutes() + '0';
    } else {
        minutes = hour.getMinutes().toString();
    }
    if (hour.getHours() < 12) {
        fullHour = hour.getHours() + ':' + minutes + ' AM';
    } else {
        fullHour = hour.getHours() + ':' + minutes + ' PM';
    }

    return fullHour;
}

type IProps = PropsFromRedux & {
    event : Event;
    history : any;
    variation : number;
    paymentObjectPartOne : LatestBuyTicketStep2Object;
    continueButtonFunction : (status:any, message:string)=>void;
    backButtonFunction : ()=>void;
};

const Step3BuyTicket = ({
    event,
    persisted,
    history,
    variation,
    continueButtonFunction,
    backButtonFunction,
    paymentObjectPartOne,
    ...props
}:IProps)=>{

    const currentUser = props.auth.currentUser;

    const [paymentName, setPaymentName] = useState((persisted.latestBuyTicketStep3Object.paymentName !== '')?(persisted.latestBuyTicketStep3Object.paymentName):(currentUser.is_fake === 0)?(currentUser.name):'');
    const [paymentLastname, setPaymentLastname] = useState((persisted.latestBuyTicketStep3Object.paymentLastname !== '')?(persisted.latestBuyTicketStep3Object.paymentLastname):(currentUser.is_fake === 0)?(currentUser.surname):'');
    const [paymentStreet1, setPaymentStreet1] = useState((persisted.latestBuyTicketStep3Object.paymentStreet1 !== '')?(persisted.latestBuyTicketStep3Object.paymentStreet1):'');
    const [paymentZip, setPaymentZip] = useState((persisted.latestBuyTicketStep3Object.paymentZip !== '')?(persisted.latestBuyTicketStep3Object.paymentZip):'');
    const [paymentCity, setPaymentCity] = useState((persisted.latestBuyTicketStep3Object.paymentCity !== '')?(persisted.latestBuyTicketStep3Object.paymentCity):'');
    const [paymentState, setPaymentState] = useState((persisted.latestBuyTicketStep3Object.paymentLastname !== '')?(persisted.latestBuyTicketStep3Object.paymentState):'');

    let constantMail = '';
    if(
        props.auth.currentUser.id == 0 ||
        props.auth.currentUser.is_fake === 0
    ){
        constantMail = props.auth.currentUser.email;
    }
    const [paymentEmail, setPaymentEmail] = useState((constantMail !== '')?constantMail:(persisted.latestBuyTicketStep3Object.paymentEmail !== '')?(persisted.latestBuyTicketStep3Object.paymentEmail):'');

    const [creditCardName, setCreditCardName] = useState((persisted.latestBuyTicketStep3Object.creditCardName !== '')?(persisted.latestBuyTicketStep3Object.creditCardName):'');
    const [creditCardNumber, setCreditCardNumber] = useState((persisted.latestBuyTicketStep3Object.creditCardNumber !== '')?(persisted.latestBuyTicketStep3Object.creditCardNumber):'');
    const [creditCardCvc, setCreditCardCvc] = useState((persisted.latestBuyTicketStep3Object.creditCardCvc !== '')?(persisted.latestBuyTicketStep3Object.creditCardCvc):'');
    const [creditCardExpirationMonth, setCreditCardExpirationMonth] = useState((persisted.latestBuyTicketStep3Object.creditCardExpirationMonth !== '')?(persisted.latestBuyTicketStep3Object.creditCardExpirationMonth):'none');
    const [creditCardExpirationYear, setCreditCardExpirationYear] = useState((persisted.latestBuyTicketStep3Object.creditCardExpirationYear !== '')?(persisted.latestBuyTicketStep3Object.creditCardExpirationYear):'none');



    const startHour = new Date((event !== null) ? (event.start.replaceAll('.000000Z','')) : (0));
    const endHour = new Date((event !== null) ? (event.end.replaceAll('.000000Z','')) : (0));


    const selectedVariation : Price = event.prices.filter(obj => {
        return obj.id === variation
    })[0];


    const [isPending,setIsPending] = useState<boolean>(false);
    if(isPending){
        return (
            <LoadingIndicator/>
        );
    }

    return(
        <div className={'payment-container'}>
            <div className={'content'} style={{paddingBottom:0}}>

                <div className={'column'}>


                    <div className={'payment-card'}>
                        <div className={'segment no-background'}>

                            <span className={'title'}>
                                Billing Details
                            </span>
                        </div>
                        <div className={'segment'}>

                            <TextInput
                                inputType="text"
                                placeholder="Your name"
                                label="Name"
                                inputValue={paymentName}
                                onTextChange={(text)=>{
                                    setPaymentName(text);
                                }}
                            />
                            <TextInput
                                inputType="text"
                                placeholder="Your lastname"
                                label="LASTNAME"
                                inputValue={paymentLastname}
                                onTextChange={(text)=>{
                                    setPaymentLastname(text);
                                }}
                            />
                            <TextInput
                                inputType="text"
                                placeholder="Street name, apt/suite number"
                                label="Address"
                                inputValue={paymentStreet1}
                                onTextChange={(text)=>{
                                    setPaymentStreet1(text);
                                }}
                            />
                            <TextInput
                                inputType="text"
                                placeholder="ZIP code"
                                label="ZIP Code"
                                inputValue={paymentZip}
                                onTextChange={(text)=>{
                                    setPaymentZip(text);
                                }}
                            />
                            <TextInput
                                inputType="text"
                                placeholder="City"
                                label="City"
                                inputValue={paymentCity}
                                onTextChange={(text)=>{
                                    setPaymentCity(text);
                                }}
                            />
                            <SelectInput
                                placeholder="State"
                                label="State"
                                inputValue={paymentState}
                                onChange={(value:string)=>{
                                    setPaymentState(value);
                                }}
                                values={USAStates}
                            />
                            <TextInput
                                inputType="text"
                                placeholder="Your email"
                                label="Your Email"
                                inputValue={paymentEmail}
                                onTextChange={(text)=>{
                                    setPaymentEmail(text);
                                }}
                                disabled={(constantMail !== '')}
                            />
                        </div>
                    </div>

                    <div className={'payment-card'}>
                        <div className={'segment no-background'}>

                            <span className={'title'}>
                                Credit Card Details
                            </span>
                        </div>
                        <div className={'segment'}>

                            <TextInput
                                inputType="text"
                                placeholder="Name on your card"
                                label="NAME ON YOUR CARD"
                                inputValue={creditCardName}
                                onTextChange={(text)=>{
                                    setCreditCardName(text);
                                }}
                            />
                            <TextInput
                                inputType="text"
                                placeholder="Credit card number"
                                label="CREDIT CARD NUMBER"
                                inputValue={creditCardNumber}
                                onTextChange={(text)=>{
                                    setCreditCardNumber(text);
                                }}
                            />

                            <div className={'select-side-by-side'}>
                                <div>
                                    <SelectInput
                                        placeholder="MM"
                                        label="Expiration Month"
                                        inputValue={creditCardExpirationMonth}
                                        onChange={(value:string)=>{
                                            setCreditCardExpirationMonth(value);
                                        }}
                                        values={[
                                            {title:'-- Select --',value:'none'},
                                            {title:'01',value:'01'},
                                            {title:'02',value:'02'},
                                            {title:'03',value:'03'},
                                            {title:'04',value:'04'},
                                            {title:'05',value:'05'},
                                            {title:'06',value:'06'},
                                            {title:'07',value:'07'},
                                            {title:'08',value:'08'},
                                            {title:'09',value:'09'},
                                            {title:'10',value:'10'},
                                            {title:'11',value:'11'},
                                            {title:'12',value:'12'}
                                        ]}
                                    />
                                </div>
                                <div>
                                    <SelectInput
                                        placeholder="MM"
                                        label="Expiration Year"
                                        inputValue={creditCardExpirationYear}
                                        onChange={(value:string)=>{
                                            setCreditCardExpirationYear(value);
                                        }}
                                        values={CreditCardExpirationYearValues}
                                    />
                                </div>
                            </div>
                            <TextInput
                                inputType="text"
                                placeholder="CVC/CVV code"
                                label="CVC/CVV CODE"
                                inputValue={creditCardCvc}
                                onTextChange={(text)=>{
                                    setCreditCardCvc(text);
                                }}
                            />
                        </div>
                    </div>

                    <div className={'payment-card'}>
                        <div className={'segment no-border no-padding'} style={{border:'none'}}>

                            <span className={'button full-width special'} onClick={()=>{
                                setIsPending(true);
                                let errors : string[] = [];
                                let object : LatestBuyTicketStep3Object = EmptyLatestBuyTicketStep3Object;

                                // Billing first name validation
                                if(
                                    paymentName.length > 1
                                ){
                                    object.paymentName = paymentName;
                                }else{
                                    errors.push('First name is not valid!');//todo:error text
                                }

                                // Billing last name validation
                                if(
                                    paymentLastname.length > 1
                                ){
                                    object.paymentLastname = paymentLastname;
                                }else{
                                    errors.push('Last name is not valid!');//todo:error text
                                }

                                // Billing street validation
                                if(
                                    paymentStreet1.length > 1
                                ){
                                    object.paymentStreet1 = paymentStreet1;
                                }else{
                                    errors.push('Address is not valid!');//todo:error text
                                }

                                // Billing zip validation
                                if(
                                    paymentZip.length > 1
                                ){
                                    object.paymentZip = paymentZip;
                                }else{
                                    errors.push('ZIP code is not valid!');//todo:error text
                                }

                                // Billing city validation
                                if(
                                    paymentCity.length > 1
                                ){
                                    object.paymentCity = paymentCity;
                                }else{
                                    errors.push('City is not valid!');//todo:error text
                                }

                                // Billing state validation
                                if(
                                    paymentCity.length > 1
                                ){
                                    object.paymentState = paymentState;
                                }else{
                                    errors.push('Please select a valid State!');//todo:error text
                                }

                                // Billing email validation
                                if(
                                    paymentEmail !== '' &&
                                    validateEmail(paymentEmail)
                                ){
                                    object.paymentEmail = paymentEmail;
                                }else{
                                    errors.push('Email is not valid!');//todo:error text
                                }

                                // Credit Card name validation
                                if(
                                    creditCardName.length > 1
                                ){
                                    object.creditCardName = creditCardName;
                                }else{
                                    errors.push('Name for credit card is not valid!');//todo:error text
                                }

                                // Credit Card number validation
                                let tempCreditCardNumber = creditCardNumber.replaceAll(' ','');
                                if(
                                    tempCreditCardNumber.length > 14 &&
                                    tempCreditCardNumber.length < 17
                                ){
                                    object.creditCardNumber = tempCreditCardNumber;
                                }else{
                                    errors.push('Credit card number is not valid!');//todo:error text
                                }

                                // Credit Card exp month validation
                                if(
                                    creditCardExpirationMonth !== 'none'
                                ){
                                    object.creditCardExpirationMonth = creditCardExpirationMonth;
                                }else{
                                    errors.push('Please select the expiration month!');//todo:error text
                                }

                                // Credit Card exp year validation
                                if(
                                    creditCardExpirationYear !== 'none'
                                ){
                                    object.creditCardExpirationYear = creditCardExpirationYear;
                                }else{
                                    errors.push('Please select the expiration year!');//todo:error text
                                }

                                // Credit Card cvc/cvv validation
                                if(
                                    creditCardCvc.length > 2 &&
                                    creditCardCvc.length < 6
                                ){
                                    object.creditCardCvc = creditCardCvc;
                                }else{
                                    errors.push('CVC/CVV for credit card is not valid!');//todo:error text
                                }

                                if(errors.length > 0){
                                    let errorMessage = '';
                                    errors.forEach((singleErrorText)=>{
                                        errorMessage += singleErrorText+'\n';
                                    });
                                    setTimeout(()=>{
                                        setIsPending(false);
                                    },500);
                                    alert(errorMessage);
                                }else{

                                    dispatchWithPromise(SetLatestBuyTicketStep3Object({
                                        paymentName: paymentName,
                                        paymentLastname: paymentLastname,
                                        paymentStreet1: paymentStreet1,
                                        paymentZip: paymentZip,
                                        paymentCity: paymentCity,
                                        paymentState: paymentState,
                                        paymentEmail: paymentEmail,
                                        creditCardName: creditCardName,
                                        creditCardNumber: creditCardNumber,
                                        creditCardCvc: creditCardCvc,
                                        creditCardExpirationMonth: creditCardExpirationMonth,
                                        creditCardExpirationYear: creditCardExpirationYear,
                                    })).then(()=>{

                                        // Actual payment process
                                        MakePayment(event.id,{
                                            'price_variation_id' : variation,
                                            'email' : paymentEmail,
                                            'name' : paymentName + ' ' + paymentLastname,
                                            'address' : paymentStreet1 + ' ' + paymentZip + ' ' + paymentCity + ' ' + paymentState,
                                            'invoice_name' : paymentName,
                                            'invoice_last_name' : paymentLastname,
                                            'invoice_zip' : paymentZip,
                                            'invoice_city' : paymentCity,
                                            'invoice_state' : paymentState,
                                            'participant_email' : paymentObjectPartOne.inputEmailParticipant,
                                            'participant_first_name' : paymentObjectPartOne.inputFirstName,
                                            'participant_last_name' : paymentObjectPartOne.inputLastName,
                                            'participant_gender' : paymentObjectPartOne.inputGender,
                                            'participant_date_of_birth' : paymentObjectPartOne.inputBirthdayMonth + ' / ' + paymentObjectPartOne.inputBirthdayDay + ' / ' + paymentObjectPartOne.inputBirthdayYear,
                                            'participant_phone_number' : paymentObjectPartOne.inputPhoneNumber,
                                            'estimated_running_time' : paymentObjectPartOne.inputEstimatedRunningTime,
                                            't_shirt_size' : paymentObjectPartOne.inputTShirtSize,
                                            'emergency_contact_name' : paymentObjectPartOne.inputEmergencyName,
                                            'emergency_contact_phone_number' : paymentObjectPartOne.inputEmergencyPhone,
                                            'agree_terms' : (paymentObjectPartOne.inputTerms)?('Agree'):('Reject'),
                                            'card' : creditCardNumber,
                                            'exp_month' : creditCardExpirationMonth,
                                            'exp_year' : creditCardExpirationYear,
                                            'cvc' : creditCardCvc,
                                        }).then((result)=>{
                                            setIsPending(false);
                                            continueButtonFunction('success','');
                                        }).catch((result)=>{
                                            // todo : error message
                                            alert(result.message);
                                            setIsPending(false);
                                        });
                                    });
                                }

                            }}>
                                Submit Payment
                            </span>

                        </div>
                    </div>


                </div>

                <div className={'column'}>

                    <div className={'payment-card'}>

                        <div className={'segment no-background'}>

                                <span className={'title'}>
                                    Review Information
                                </span>

                        </div>

                        <div className={'segment'}>

                            <div className={'info'}>
                                <div>
                                    <span>
                                        Event Name
                                    </span>
                                    <p>
                                        {event.title}
                                    </p>
                                </div>
                            </div>

                        </div>


                        <div className={'segment'}>

                            <div className={'info'}>
                                <div>
                                    <span>
                                        Race Type
                                    </span>
                                    <p>
                                        {selectedVariation.title}<br/>{selectedVariation.description}
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className={'segment'}>

                            <div className={'info'}>
                                <div>
                                        <span>
                                            DATE
                                        </span>
                                    <p>
                                        {daysOfWeek[startHour.getDay()]}, {startHour.getDate()} {monthNames[startHour.getMonth()]}
                                    </p>
                                </div>
                                <div>
                                        <span>
                                            TIME
                                        </span>
                                    <p>
                                        {getRightTime(startHour)} - {getRightTime(endHour)}
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className={'segment'}>

                            <div className={'info'}>
                                <div>
                                        <span>
                                            Location
                                        </span>
                                    <p>
                                        {event.location.state}
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className={'segment'}>

                            <div className={'info'}>
                                <div>
                                        <span>
                                            Address
                                        </span>
                                    <p>
                                        {event.location.address}
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className={'segment'}>

                            <span className={'price'}>
                                ${selectedVariation.price}
                            </span>

                        </div>

                    </div>

                    <div className={'payment-card'}>
                        <div className={'segment no-border no-padding'} style={{border:'none'}}>

                            <span className={'button full-width no-special'} onClick={()=>{
                                backButtonFunction();
                            }} style={{color:'#999999', borderColor:'#999999'}}>
                                Back to Step 2
                            </span>

                        </div>
                    </div>

                </div>

            </div>

            {/*<div className="content" style={{paddingTop:0}}>*/}
            {/*    <div className="column">*/}

            {/*    </div>*/}
            {/*    <div className="column">*/}

            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}

function mapStateToProps(state:IReduxPart){
    return{
        auth : state.auth,
        persisted : state.persisted
    }
}
const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Step3BuyTicket);
