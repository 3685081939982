import React, {useEffect, useState} from "react";
import {GetFavoriteEvents} from "../../utils/api/InnerPageServices";
import RaceButton from "../raceButton/RaceButton";
import LoadingIndicator from "../../sharedComponents/loading/LoadingIndicator";
import {Event} from "../../utils/ServiceTypes";
import RaceButtonInside from "../raceButtonInside/RaceButtonInside";
import SimpleButton from "../../sharedComponents/simpleButton/SimpleButton";
import {NavLink} from "react-router-dom";

type IProps = {
    link?: string;
}

const ViewAllButton = ({
    link = '',
}: IProps) => {
    return (
        <div className="view-all" style={{marginBottom: 60}}>
            <NavLink activeClassName={'active'} to={'/' + link}>
                VIEW ALL
            </NavLink>
        </div>
    );
};

const FavoritesInline = ()=>{

    const [isPending,setIsPending] = useState<boolean>(true);
    const [events,setEvents] = useState<Event[]>([]);

    useEffect(()=>{
        GetFavoriteEvents().then((result)=>{
            setEvents(result.events);
            setIsPending(false);
        }).catch(()=>{
            setEvents([]);
            setIsPending(false);
        })
    },[]);

    return(
        <div className="my-profile-row direction-column">
            <div className="race-button-container">

                <RaceButton
                    title={'Favorites'}
                />
                {(isPending) ? (
                    <LoadingIndicator/>
                ) : (
                    (events.length > 0) ? (
                        <React.Fragment>
                            {events.slice(0,3).map((item: Event) => {
                                return (
                                    <React.Fragment key={'uniqueKey' + item.id}>
                                        <RaceButtonInside
                                            item={item}
                                        />
                                        <hr/>
                                    </React.Fragment>
                                )
                            })}
                            <ViewAllButton link={'favorites'}/>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <h3 className={'warningMessage'}>You have no favorite races.</h3>
                            <SimpleButton title={'Find a Race Now'} link={'search'}/>
                        </React.Fragment>
                    ))}
                <br/>
            </div>
        </div>
    );

}

export default FavoritesInline;
