import React, {ReactElement} from "react";
import BodyText from "../bodyText/BodyText";
import GreyText from "../greyText/GreyText";
import Header from "../header/Header";
import './MedalAttendedRace.scss'
import {Medal} from "../../utils/ServiceTypes";

const monthNames = ['January','February','March','April','May','June','July','August','September','October','November','December'];
const daysOfWeek = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];


interface IProps {
    medal : Medal;
}

const MedalAttendedRace = (props: IProps) => {
    return (
        <div className={'attended-race-container'}>
            {(props.medal.results.map((event)=>{
                if(event.result === null){
                    return null;
                }

                const date = new Date(event.start);
                return(
                    <div className={'border-container'} key={"unique"+event.id}>
                        <div className={'attended-race-marathon'}>
                            <BodyText isBold={false}>{event.title}</BodyText>
                        </div>
                        <div className={'slash'}>
                            /
                        </div>
                        <div className={'attended-race-date'}>
                            <GreyText isBold>{''+daysOfWeek[date.getDay()]+', '+date.getDate()+' '+monthNames[date.getMonth()]}</GreyText>
                        </div>
                        <div className={'yellow-miles-area'}>
                            <Header>{event.result.miles.toString()}</Header><p> miles</p>
                        </div>
                    </div>
                );
            }))}
        </div>
    );
}

export default MedalAttendedRace;
