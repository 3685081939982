import React from "react";
import "./ResultsInputArea.scss";

interface IProps {
    placeholder: string;
    inputType: string;
    children: string;
    inputValue: string;
    onChangeInputValue: (e: any) => void;
    pattern: string;
}

function ResultsInputArea({
                              placeholder = '',
                              inputType = '',
                              children = '',
                              inputValue = '',
                              pattern = '',
                              onChangeInputValue = () => {
                              }
                          }: IProps) {
    return (
        <div className={'results-input-area'}>

            <input type={inputType} pattern={pattern} id={inputType} name={inputType} placeholder={placeholder}
                   value={inputValue} onChange={onChangeInputValue}/>
            <label htmlFor={inputType}>{children}</label><br/>

        </div>
    );
}

export default ResultsInputArea;
