import React, {ChangeEvent} from "react";
import {DatePickerComponent} from "@syncfusion/ej2-react-calendars";
import "./ProfileEditView.scss";
import PageHeader from "../../sharedComponents/pageHeader/PageHeader";
import UploadIcon from './assets/group-108.png';
import GetProfileDetails, {UpdateProfile} from "../../utils/api/InnerPageServices";
import AddAvatarPhoto from "../myProfile/assets/add-photo.svg";
import LoadingIndicator from "../../sharedComponents/loading/LoadingIndicator";

import imageCompression from 'browser-image-compression';
import PasswordChanger from "./PasswordChanger";

const compressOptions = {
    maxSizeMB: 1,          // (default: Number.POSITIVE_INFINITY)
    maxWidthOrHeight: 512,   // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
};


class DatePicker extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            date: new Date().getFullYear(),
            month: new Date().getMonth(),
        };
        this.handleChangeDatepicker = this.handleChangeDatepicker.bind(this);
    }

    handleChangeDatepicker(event: any) {
        let date = event.target.date;
        let month = event.target.month;
        this.setState(date);
        this.setState(month);
    }

    render() {
        const dateValue: Date = new Date(this.state.date, this.state.month, 15);
        return (
            <div>
                <DatePickerComponent placeholder="Enter Date"
                                     value={dateValue}
                                     format="MMMM dd, yyyy"
                ></DatePickerComponent>
            </div>
        );
    }
}

type ChangeableArea = 'name' | 'lastname' | 'email' | 'description' | 'profilePicture';

type IState = {
    isPending : boolean;
    name: string;
    lastname: string;
    email: string;
    description: string;
    errorMessage: string;
    profilePicture: null | string;
    changedAreas : ChangeableArea[];
}

class ProfileEditView extends React.Component<any, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            isPending : true,
            profilePicture : null,
            name: '',
            lastname: '',
            email: '',
            description: '',
            changedAreas : [],
            errorMessage : ''
        };

        this.getUserDetails = this.getUserDetails.bind(this);
        this.saveNewProfile = this.saveNewProfile.bind(this);

    }

    async saveNewProfile(event : null | ChangeEvent<HTMLInputElement> = null){
        this.setState({
            isPending : true
        });
        const component = this;
        let changeableAreas = this.state.changedAreas;

        let formData = new FormData();
        formData.append('_method','PUT');
        if(
            event && event.target && event.target.files &&
            event.target.files[0]
        ){
            let file = await imageCompression( event.target.files[0], compressOptions);
            formData.append('profile',file);
        }


        changeableAreas.forEach((item)=>{
            if(item === 'name'){
                formData.append('name',this.state.name);
            }else if(item === 'lastname'){
                formData.append('surname',(this.state.lastname)?this.state.lastname:'');
            }else if(item === 'description'){
                formData.append('description',this.state.description);
            }else if(item === 'email'){
                formData.append('email',this.state.email);
            }
        });

        UpdateProfile(formData).then((result)=>{
            component.props.history.push('/profile');
            // component.setState({
            //     isPending : false,
            //     name : result.name,
            //     lastname : result.surname,
            //     email : result.email,
            //     description : result.description,
            //     profilePicture : (result.profile.charAt(result.profile.length - 1) !== '/')?(result.profile):null,
            //     errorMessage : ''
            // });
        }).catch((message)=>{
            if(message !== ''){
                alert(message);
            }
            component.setState({
                isPending : false,
                errorMessage : '' // todo enter error message
            });
        });

    }

    getUserDetails(){
        const component = this;
        this.setState({
            isPending : true
        });
        GetProfileDetails().then((result)=>{
            component.setState({
                isPending : false,
                name : result.name,
                lastname : result.surname,
                email : result.email,
                description : result.description,
                profilePicture : (result.profile.charAt(result.profile.length - 1) !== '/')?(result.profile):null,
                errorMessage : ''
            });
        }).catch(()=>{
            component.setState({
                isPending : false,
                errorMessage : '' // todo enter error message
            });
        });
    }

    componentDidMount() {
        this.getUserDetails();
    }

    render() {

        const component = this;

        return (
            <div className={'content-body'}>

                <PageHeader
                    title={'Edit Profile'}
                    buttonElement={
                        <div
                            className={'edit-profile-button yellow'}
                            onClick={() => {
                                if(!component.state.isPending){
                                    component.saveNewProfile();
                                }
                            }}
                        >
                            SAVE
                        </div>
                    }
                />

                {(this.state.isPending)?(
                    <LoadingIndicator/>
                ):(
                    <div className="form-container">
                        <div>
                            <div className="profile-photo">
                                <div className="title">
                                    Profile photo
                                </div>
                                <div className="img">

                                    {(
                                        this.state.profilePicture &&
                                        this.state.profilePicture.charAt(this.state.profilePicture.length - 1) !== '/'
                                    )?(
                                        <div className={'image'}>
                                            <span style={{backgroundImage:'url('+this.state.profilePicture+')'}}/>
                                        </div>
                                    ):(
                                        <div style={{flex:0, width:'100%', maxWidth:'120px', position:'relative', overflow:'hidden', borderRadius: '50%', border: '10px solid #f2f2f2', height:'auto'}}>
                                            <img src={AddAvatarPhoto} alt={'Add photo'} style={{width:'40%', margin:'30%'}}/>
                                        </div>
                                    )}

                                </div>
                                <div className="upload-img">
                                    <label htmlFor={'upload-button'}>
                                        <div className={'chooseFile'}>
                                            Upload new photo <img src={UploadIcon} alt=""/>
                                        </div>
                                        <input type="file" id="upload-button" style={{display: 'none'}}
                                               onChange={(event) => {
                                                   component.saveNewProfile(event);
                                               }}/>
                                    </label>
                                </div>
                            </div>
                            <div className="form">
                                <div className="row">
                                    <label htmlFor="name">
                                        <div className="title">
                                            NAME
                                        </div>
                                        <input type="text" id={'name'} value={this.state.name}
                                               onChange={(event) => {
                                                   let changeableAreas = component.state.changedAreas;
                                                   if(!changeableAreas.includes('name')){
                                                       changeableAreas.push('name');
                                                   }
                                                   component.setState({
                                                       name: event.target.value,
                                                       changedAreas : changeableAreas
                                                   });
                                               }}/>
                                    </label>
                                    <label htmlFor="lastname">
                                        <div className="title">
                                            LASTNAME
                                        </div>
                                        <input type="text" id={'lastname'} value={this.state.lastname}
                                               onChange={(event) => {
                                                   let changeableAreas = component.state.changedAreas;
                                                   if(!changeableAreas.includes('lastname')){
                                                       changeableAreas.push('lastname');
                                                   }
                                                   component.setState({
                                                       lastname: event.target.value,
                                                       changedAreas : changeableAreas
                                                   });
                                               }}/>
                                    </label>
                                </div>
                                {/*<div className="row">*/}
                                {/*    /!*<label htmlFor="email">*!/*/}
                                {/*    /!*    <div className="title">*!/*/}
                                {/*    /!*        EMAIL*!/*/}
                                {/*    /!*    </div>*!/*/}
                                {/*    /!*    <input type="email" id={'email'} required value={this.state.email}*!/*/}
                                {/*    /!*           onChange={(event) => {*!/*/}
                                {/*    /!*               let changeableAreas = component.state.changedAreas;*!/*/}
                                {/*    /!*               if(!changeableAreas.includes('email')){*!/*/}
                                {/*    /!*                   changeableAreas.push('email');*!/*/}
                                {/*    /!*               }*!/*/}
                                {/*    /!*               component.setState({*!/*/}
                                {/*    /!*                   email: event.target.value,*!/*/}
                                {/*    /!*                   changedAreas : changeableAreas*!/*/}
                                {/*    /!*               });*!/*/}
                                {/*    /!*           }}/>*!/*/}
                                {/*    /!*</label>*!/*/}
                                {/*    /!*<label htmlFor="lastname">*!/*/}
                                {/*    /!*    <div className="title">*!/*/}
                                {/*    /!*        DATE OF BIRTH*!/*/}
                                {/*    /!*        <DatePicker/>*!/*/}
                                {/*    /!*    </div>*!/*/}
                                {/*    /!*</label>*!/*/}
                                {/*</div>*/}
                                <div className="row">
                                    <label htmlFor="aboutMe">
                                        <div className="title">
                                            ABOUT ME
                                        </div>
                                        <textarea value={this.state.description} onChange={(event) => {
                                            let changeableAreas = component.state.changedAreas;
                                            if(!changeableAreas.includes('description')){
                                                changeableAreas.push('description');
                                            }
                                            component.setState({
                                                description: event.target.value,
                                                changedAreas : changeableAreas
                                            });
                                        }} id={'aboutMe'}/>
                                    </label>
                                </div>

                                <PasswordChanger/>

                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

}

export default ProfileEditView;
