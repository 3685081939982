import React from "react";

type IProps = {
    description : string;
}

const OnlyInputDescription = ({
    description = ''
}:IProps)=>{
    return(
        <div className="custom-text-input-description">{description}</div>
    );
}

export default OnlyInputDescription;
