import React from "react";
import "./Register.scss";
import InputArea from "../../sharedComponents/inputArea/InputArea";
import Button from "../../sharedComponents/buttonTheme/Button";
import OuterPages from "../../sharedComponents/outerPagesLayout/OuterPagesLayout";
import TextSmall from "../../sharedComponents/textSmall/TextSmall";
import RegisterARunner from "../../utils/api/OuterPageServies";
import OuterPagesButton from "../../sharedComponents/outerPagesButton/OuterPagesButton";
import {NavLink} from "react-router-dom";
import store, {dispatchWithPromise} from "../../redux";
import {setIsLoginBeforeTrue} from "../../utils/ProtectedRoute";
import {ClearPrePaymentRegisterRedirect} from "../../redux/temporary-app-state/actions";
import {RedirectToPayment} from "../../utils/PaymentHelpers";


interface IProps {
    history : {
        push : any;
    }
}

interface IState {
    name: string;
    surname: string;
    email: string;
    password: string;
    confirmPassword: string;
}

class Register extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            name: '',
            surname: '',
            email: '',
            password: '',
            confirmPassword: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleSurnameChange = this.handleSurnameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    }

    handleSubmit = (event: any) => {
        event.preventDefault();
        const component = this;

        RegisterARunner(
            this.state.name,
            this.state.surname,
            this.state.email,
            this.state.password,
            this.state.confirmPassword
        ).then(() => {
            setIsLoginBeforeTrue();
            if(store.getState().favorites.waitingAction !== null){
                // @ts-ignore
                component.props.history.push('/event-info/'+store.getState().favorites.waitingAction?.eventId);
                return
            }
            if(store.getState().temporaryAppState.prePaymentRegisterRedirect.isActive){
                const eventId = store.getState().temporaryAppState.prePaymentRegisterRedirect.eventId;
                dispatchWithPromise(
                    ClearPrePaymentRegisterRedirect()
                ).then(()=>{
                    RedirectToPayment(eventId,component.props.history);
                });
                return;
            }

            // @ts-ignore
            component.props.history.push('/profile');
        }).catch((result) => {
            if(result.message){
                alert(result.message);
            }else{
                alert('An error occurred!');
            }
        });

    }

    handleNameChange = (event: any) => {
        this.setState({
            name: event.target.value,
        });
    }

    handleSurnameChange = (event: any) => {
        this.setState({
            surname: event.target.value,
        });
    }

    handleEmailChange = (event: any) => {
        this.setState({
            email: event.target.value,
        });
    }

    handlePasswordChange(event: any) {
        this.setState({
            password: event.target.value
        });
    }

    handleConfirmPasswordChange(event: any) {
        this.setState({
            confirmPassword: event.target.value
        });
    }

    render() {
        const {email, name, surname} = this.state;
        return (
            <div className={'register-page'}>
                <OuterPages headerTitle={'SIGN UP'} bgImg='/image/bg-signup.jpg'>
                    <div className={'form-area'}>
                        <form onSubmit={this.handleSubmit}>
                            <InputArea
                                inputType="text"
                                placeholder="Enter your name"
                                children="NAME"
                                inputValue={name}
                                onChangeInputValue={this.handleNameChange}
                            />
                            <InputArea
                                inputType="text"
                                placeholder="Enter your lastname"
                                children="LASTNAME"
                                inputValue={surname}
                                onChangeInputValue={this.handleSurnameChange}
                            /><InputArea
                                inputType="email"
                                placeholder="Enter email address"
                                children="EMAIL"
                                inputValue={email}
                                onChangeInputValue={this.handleEmailChange}
                            />
                            <InputArea
                                inputType="password"
                                placeholder="Enter password"
                                children="PASSWORD"
                                inputValue={this.state.password}
                                onChangeInputValue={this.handlePasswordChange}
                            />
                            <InputArea
                                inputType="password"
                                placeholder="Re-enter password"
                                children="RE-ENTER PASSWORD"
                                inputValue={this.state.confirmPassword}
                                onChangeInputValue={this.handleConfirmPasswordChange}
                            />
                            <OuterPagesButton type={'submit'} blackButton>SIGN UP NOW</OuterPagesButton>
                        </form>

                    </div>
                    <div className={'back-to-sign'}>
                        <TextSmall>Already have an account? Sign in.</TextSmall>
                    </div>
                    <div className={'login-button'}>
                        <NavLink to={'login'}>
                            <OuterPagesButton type={'button'} blackButton={false}>LOG IN</OuterPagesButton>
                        </NavLink>
                    </div>
                </OuterPages>
            </div>
        );
    }

}

export default Register;
