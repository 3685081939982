import {createStore, applyMiddleware, combineReducers} from 'redux';
import auth, {IReduxAuth} from './auth';
import persisted, {IReduxPersisted} from './persisted';
import favorites, {IReduxFavorites} from "./favorites";
import temporaryAppState, {IReduxTemporaryAppState} from "./temporary-app-state";


export interface IReduxPart {
  auth : IReduxAuth;
  favorites : IReduxFavorites;
  persisted : IReduxPersisted;
  temporaryAppState : IReduxTemporaryAppState;
}

interface IStore {
  getState() : IReduxPart;
  dispatch(action:any) : any;
  subscribe : any;
}

const reducers : any = combineReducers({
  auth,
  favorites,
  persisted,
  temporaryAppState
});

const createStoreWithMiddleware
    = applyMiddleware(
    // save() // Saving done here
)(createStore);

const store : IStore = createStoreWithMiddleware(
    reducers
);

export function dispatchWithPromise(action:any) {
  return new Promise((resolve) => {
    store.dispatch(action);
    resolve(true);
  });
}

export default store;
