import React from "react";
import "./InputArea.scss";

interface IProps {
    placeholder: string;
    inputType: string;
    children: string;
    inputValue: string;
    fullWidth?: boolean;
    disabled?: boolean;
    onChangeInputValue: (e:any) => void;
}

function InputArea({
                       placeholder = '',
                       inputType = '',
                       children = '',
                       inputValue = '',
                       onChangeInputValue = () => {},
                       fullWidth = false,
                       disabled = false
                   }: IProps)
{
    return (
        <div className={(fullWidth)?('input-area full-width'):('input-area')}>

            {(children === '')?(
                <div style={{marginTop:'-30px'}}></div>
            ):(
                <React.Fragment>
                    <label htmlFor={inputType}>{children}</label><br/>
                </React.Fragment>
            )}
            <input type={inputType} id={inputType} name={inputType} placeholder={placeholder} value={inputValue} onChange={onChangeInputValue} disabled={disabled}/>

        </div>
    );
}

export default InputArea;
