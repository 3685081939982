import React from 'react';
import './SearchRaces.scss';
import ListView from "./views/ListView/ListView";
import SeriesEvent, {ListEventMapContent, ListEventRenderContent} from "./types/Types";
import {
    groupEvents, groupEventsForMap,
    searchEventsForCalendar,
} from "./utils/Utils";
import FilterView from "./views/FilterView/FilterView";
import { ServiceCall } from '../../utils/ServiceCall';


interface IState {
    eventsRaw: SeriesEvent[];
    eventList: SeriesEvent[];
    renderContent: ListEventRenderContent[];
    mapEvents: ListEventMapContent[];
    filterType: 'date' | 'region';
}

class SearchRaces extends React.Component<any, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            eventsRaw: [],
            eventList: [],
            renderContent: [],
            mapEvents: [],
            filterType: 'date'
        };

        this.getEvents = this.getEvents.bind(this);
        this.saveEvents = this.saveEvents.bind(this);

    }

    saveEvents(events: SeriesEvent[], type: 'region' | 'date' | 'search', searchInput: string = '') {
        this.setState({
            eventList: searchEventsForCalendar(events, type, searchInput),
            renderContent: groupEvents(events, type, searchInput),
            mapEvents: groupEventsForMap(events, type, searchInput)
        })
    }

    getEvents() {
        const component = this;
        ServiceCall('GET', 'events', '').then((response) => {
            component.setState({
                eventsRaw: response.data.data
            });
        });
    }

    componentDidMount() {
        this.getEvents();
    }

    render() {
        

        // @ts-ignore
        return (
            <div className="App-Search-Races">

                <FilterView
                    saveEvents={this.saveEvents}
                    events={this.state.eventsRaw}
                />

                <ListView renderContent={this.state.renderContent}/>
                
            </div>
        );
    }

}

export default SearchRaces;
