import React from "react";
import {NavLink} from "react-router-dom";
import "./OuterPagesButton.scss";

interface IProps {
    children: string;
    blackButton: boolean;
    type: 'submit' | 'button' | 'reset' | undefined;
}

function OuterPagesButton({
                              children = '',
                              blackButton = false,
                              type = 'submit'
                          }: IProps) {
    return (
        <button type={type}
                className={'outer-pages-button' + (blackButton ? ' dark-button' : ' bordered-button')}>
            {children}
        </button>
    );

}

export default OuterPagesButton;
