import React from 'react';
import {ListEventRenderContent} from "../../types/Types";
import "./ListView.scss";
import ListItem from "./components/ListItem/ListItem";
import ListItemDetail from "./components/ListItemDetail/ListItemDetail";
import ViewMore from "./components/ViewMoreButton/ViewMore";

interface IProps {
    renderContent : ListEventRenderContent[];
}
interface IState {
    renderContent : ListEventRenderContent[];
    showCount: number;
    selectedEventId: number;
}

const eventPerPage = 10;

class ListView extends React.Component<IProps, IState>{

    constructor(props:IProps) {
        super(props);
        this.state = {
            renderContent : props.renderContent,
            showCount: eventPerPage,
            selectedEventId : 0

        };
        this.showMore = this.showMore.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if(prevProps.renderContent.length !== this.props.renderContent.length){
            this.setState({
                showCount : eventPerPage,
                selectedEventId : 0
            });
        }
    }

    static getDerivedStateFromProps(nextProps:IProps){
        return{
            renderContent : nextProps.renderContent
        };
    }

    showMore(){
        this.setState({showCount: this.state.showCount + eventPerPage});
    }

    render() {

        const component = this;
        let count = 0;

        return (
            <div className={'series-runner-list-view'}>
                <div className="series-runner-list-view-wrapper">

                    {(this.state.renderContent.length > 0)?(
                        <div id={'list-of-races-render-container'}>

                            {this.state.renderContent.map((item, index)=>{
                                if(count >= this.state.showCount){
                                    return null;
                                }
                                return(
                                    <div className="steps-holder" key={'unique'+item.monthAndYearNumber + index}>
                                        <div className="steps-holder-title">{item.title}</div>
                                        <ul className="accordion">

                                            {item.events.map((event)=>{
                                                if(count >= this.state.showCount){
                                                    return null;
                                                }
                                                count++;
                                                return(
                                                    <li key={'uniqueEvent'+event.id}>

                                                        <ListItem
                                                            display={(event.id === this.state.selectedEventId)}
                                                            event={event}
                                                            selectFunction={(eventId : number = 0)=>{
                                                                component.setState({
                                                                    selectedEventId : (eventId !== component.state.selectedEventId)?(eventId):(0)
                                                                });
                                                            }}
                                                        />
                                                        <ListItemDetail
                                                            display={(event.id === this.state.selectedEventId)}
                                                            event={event}
                                                        />
                                                    </li>
                                                );
                                            })}

                                        </ul>
                                    </div>
                                );

                            })}
                            <ViewMore viewMoreOnClick={this.showMore}/>
                        </div>

                    ):(
                        <div className="lds-ellipsis active" id={'list-of-races-loading-indicator'}>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    )}

                </div>
            </div>
        );
    }

}

export default ListView;
