import React from "react";
import './Results.scss';
import PageHeader from "../../sharedComponents/pageHeader/PageHeader";
import {Link} from "react-router-dom";
import ResultsButton, {ResultStatusType} from "../../sharedComponents/resultsButton/ResultsButton";
import Button from "../../sharedComponents/buttonTheme/Button";
import {GetRunnerResults} from "../../utils/api/InnerPageServices";
import LoadingIndicator from "../../sharedComponents/loading/LoadingIndicator";
import SimpleButton from "../../sharedComponents/simpleButton/SimpleButton";
import {Event} from "../../utils/ServiceTypes";

const monthNames = ['January','February','March','April','May','June','July','August','September','October','November','December'];
const daysOfWeek = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];

interface IProps {

}

interface IState {
    isPending : boolean;
    error : boolean;
    results : Event[];
}


function getRightTime(hour: Date) {

    let minutes, fullHour: string;

    if (hour.getUTCMinutes() == 0) {
        minutes = hour.getUTCMinutes() + '0';
    } else {
        minutes = hour.getUTCMinutes().toString();
    }
    if (hour.getUTCHours() < 12) {
        fullHour = hour.getUTCHours() + ':' + minutes + ' AM';
    } else {
        fullHour = hour.getUTCHours()  + ':' + minutes + ' PM';
    }

    return fullHour;
}

class Results extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            isPending : true,
            results : [],
            error : false
        };

        this.getResults = this.getResults.bind(this);

    }

    getResults(){
        const component = this;
        GetRunnerResults().then((result)=>{
            component.setState({
                results : result,
                error: false,
                isPending : false
            });
        }).catch(()=>{
            component.setState({
                results : [],
                error: true,
                isPending : false
            });
        });
    }

    componentDidMount() {
        this.getResults();
    }

    render() {
        const component = this;
        const dateNow = Date.now();
        return (
            <div className={'content-body'}>

                <PageHeader
                    title={'Results'}
                />

                {(this.state.isPending)?(
                    <LoadingIndicator/>
                ):(
                    (this.state.results.length > 0)?(
                        <div className="results-button-container">

                            {this.state.results.map((item,index)=>{

                                let resultMiles = 0;
                                let raceState : ResultStatusType = 'UPCOMING';
                                if(item.result === null){
                                    if(
                                        new Date(item.start).getTime() > dateNow
                                    ){
                                        raceState = 'UPCOMING';
                                    }else{
                                        raceState = 'SUBMIT RESULT';
                                    }
                                }else{
                                    resultMiles = item.result.miles;
                                    if(item.result.status === 'approved'){
                                        raceState = 'COMPLETED';
                                    }else if(item.result.status === 'pending'){
                                        raceState = 'WAITING APPROVAL';
                                    }else if(item.result.status === 'rejected'){
                                        raceState = 'DENIED';
                                    }
                                }
                                const date = new Date(item.start);
                                const startHour = new Date(item.start);
                                const endHour = new Date(item.end);

                                return(
                                    <ResultsButton
                                        key={'resultUnique'+item.id}
                                        eventId={item.id}
                                        title={item.title}
                                        miles={resultMiles.toString()}
                                        date={''+daysOfWeek[date.getDay()]+', '+date.getDate()+' '+monthNames[date.getMonth()]}
                                        time={'('+daysOfWeek[date.getDay()]+', '+date.getDate()+' '+monthNames[date.getMonth()]+')'}
                                        hours={''+getRightTime(startHour)+' - '+getRightTime(endHour)}
                                        location={item.location.state}
                                        postCode={item.location.address}
                                        raceState={raceState}
                                        refreshList={()=>{
                                            component.getResults();
                                        }}
                                    />
                                );

                            })}

                        </div>
                    ):(
                        <div className={'empty-list-container'}>
                            <div className={'item'}>
                                You have not completed any races yet. List of all your Completed Races will be displayed on this results page once you start completing your registered races.
                            </div>
                            <SimpleButton title={'Find a race now'} link={'search'}/>
                        </div>
                    )
                )}


            </div>
        );
    }
}

export default Results;
