import React from "react";

type IProps = {
    label : string;
}

const OnlyInputLabel = ({
    label = ''
}:IProps)=>{
    return(
        <div className="custom-text-input-label">{label}</div>
    );
}

export default OnlyInputLabel;
