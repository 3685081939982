export interface IReduxFavorites {
    favorites: number[];
    waitingAction : null | {eventId:number, action: string};
}

interface IAction {
    type: string;
    payload : any;
}

const INIT_STATE : IReduxFavorites = {
    favorites : [],
    waitingAction : null
};

const favorites = (state:IReduxFavorites = INIT_STATE, action : IAction) => {
    switch (action.type) {
        case 'SET_CURRENT_FAVORITES':
            return {
                ...state,
                favorites: action.payload,
            };
        case 'SET_CURRENT_FAVORITES_WAITING_ACTION':
            return {
                ...state,
                waitingAction: action.payload,
            };
        default:
            return { ...state };
    }
};

export default favorites;
