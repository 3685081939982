import React from "react";
import "./ProfileView.scss";
import MyProfile from "../myProfile/MyProfile";

class ProfileView extends React.Component<any, any>{

    constructor(props:any) {
        super(props);
        this.state = {

        };
    }

    render() {
        return(
                <MyProfile/>

        );
    }

}

export default ProfileView;
