import React from "react";
import Button from "../../sharedComponents/buttonTheme/Button";
import PageHeader from "../../sharedComponents/pageHeader/PageHeader";
import RaceButtonInside from "../raceButtonInside/RaceButtonInside";
import {Event} from "../../utils/ServiceTypes";
import {GetFavoriteEvents} from "../../utils/api/InnerPageServices";
import "../raceButton/RaceButton.scss";
import LoadingIndicator from "../../sharedComponents/loading/LoadingIndicator";
import SimpleButton from "../../sharedComponents/simpleButton/SimpleButton";

interface IProps {

}

interface IState {
    events: Event[];
    isPending: boolean;
}

class Favorites extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            events: [],
            isPending: true
        }

        this.getFavoriteDetails = this.getFavoriteDetails.bind(this);
    }

    componentDidMount() {
        this.getFavoriteDetails();
    }

    getFavoriteDetails() {
        const component = this;
        GetFavoriteEvents().then((result) => {
            component.setState({
                events: result.events,
                isPending: false
            });
        }).catch(() => {
            component.setState({
                events: [],
                isPending: false
            })
        })
    }

    render() {

        return (
            <div className={'content-body'}>
                <PageHeader
                    title={'Favorites'}
                />

                {(this.state.isPending) ? (
                    <LoadingIndicator/>
                ) : (
                    (this.state.events.length > 0) ? (
                        <div className="my-profile-container">
                            <div className="my-profile-row direction-column">
                                <div className="race-button-container">

                                    {this.state.events.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <RaceButtonInside
                                                    item={item}
                                                />
                                                <hr/>
                                            </React.Fragment>
                                        )
                                    })}

                                </div>
                            </div>

                        </div>
                    ) : (
                        <div className="my-profile-container">
                            <div className="my-profile-row direction-column">
                                <div className="race-button-container">

                                    <h3 className={'warningMessage'}>You have no favorite races.</h3>

                                    <SimpleButton title={'Find a Race Now'} link={'search'}/>

                                </div>
                            </div>

                        </div>
                    )
                )}


            </div>
        );
    }
}

export default Favorites;
