import React from "react";
import "./TextSmall.scss";

interface IProps {
    children: string;
}
function TextSmall({
                    children=''
                }:IProps) {
    return (
        <div className={'text-small'}>
            {children}
        </div>
    );

}

export default TextSmall;
