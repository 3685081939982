import React, {useEffect, useState} from "react";
import GetProfileDetails, {UpdateProfile} from "../../utils/api/InnerPageServices";
import {Runner} from "../../utils/ServiceTypes";
import "../payment/Payment.scss";
import SimpleButton from "../../sharedComponents/simpleButton/SimpleButton";
import {IReduxPart} from "../../redux";
import LoadingIndicator from "../../sharedComponents/loading/LoadingIndicator";
import ErrorLogo from "./assets/logo.png";
import {connect, ConnectedProps} from "react-redux";
import TextInput from "../../sharedComponents/input/TextInput";
import SelectInput from "../../sharedComponents/input/SelectInput";
import imageCompression from "browser-image-compression";




type IProps = PropsFromRedux & {
    history : any
};

const CompleteRegister = (props:IProps)=>{

    const [currentUser, setCurrentUser] = useState<Runner|null>(null);
    const [pending, setPending] = useState<boolean>(true);


    let constantMail = '';
    if(
        currentUser !== null &&
        currentUser.is_fake === 0
    ){
        constantMail = currentUser.email;
    }
    const [formEmail, setFormEmail] = useState(constantMail);
    const [formName, setFormName] = useState('');
    const [formLastName, setFormLastName] = useState('');
    const [formPassword, setFormPassword] = useState('');
    const [formPasswordConfirm, setFormPasswordConfirm] = useState('');

    const [paymentStep, setPaymentStep] = useState<'fill'|'error'|'success'>('fill');

    useEffect(()=>{

        setPending(true);
        GetProfileDetails().then((result)=>{
            setCurrentUser(result);
            setPending(false);
        }).catch(()=>{
            setCurrentUser(null);
            setPending(false);
        });

    },[]);


    if(pending){
        return (
            <div className={'payment-container'}>
                <LoadingIndicator/>
            </div>
        );
    }
    if(currentUser === null){
        return (
            <div className={'payment-container'}>
                <img src={ErrorLogo} className={'error-icon'}/>
                <div className={'error-message'}>
                    Oops. Something went wrong. <br/>Please try again later, or <a href={'mailto:info@seriesrunner.com'}>contact us</a>.
                </div>
                <SimpleButton title={'back to search'} link={'search'}/>
            </div>
        );
    }


    if(paymentStep === 'error'){
        return (
            <div className={'payment-container'}>
                <img src={ErrorLogo} className={'error-icon'}/>
                <div className={'error-message'}>
                    <p>
                        Oops. Something went wrong. <br/>Please try again later, or <a href={'mailto:info@seriesrunner.com'}>contact us</a>.
                    </p>
                </div>
                <div className="view-all outline" style={{marginBottom: 30, marginTop: 30}}>
                    <a href={'javascript:void()'} onClick={()=>{
                        setPaymentStep('fill');
                    }}>
                        Back
                    </a>
                </div>
            </div>
        );
    }

    return (
        <div className={'payment-container'}>

            <div className={'title'}>
                <span>COMPLETE REGISTRATION</span>
            </div>

            <div className={'content'}>

                <div className={'column'}>


                    <div className={'payment-card'}>

                        <div className={'segment'}>


                            <TextInput
                                inputType="text"
                                placeholder="Your email"
                                label="Your Email"
                                inputValue={formEmail}
                                onTextChange={(text)=>{
                                    setFormEmail(text);
                                }}
                                disabled={(constantMail !== '')}
                            />
                            <TextInput
                                inputType="text"
                                placeholder="Your name"
                                label="Name"
                                inputValue={formName}
                                onTextChange={(text)=>{
                                    setFormName(text);
                                }}
                            />
                            <TextInput
                                inputType="text"
                                placeholder="Your lastname"
                                label="LASTNAME"
                                inputValue={formLastName}
                                onTextChange={(text)=>{
                                    setFormLastName(text);
                                }}
                            />
                            <TextInput
                                inputType="password"
                                placeholder="Enter password"
                                label="PASSWORD"
                                inputValue={formPassword}
                                onTextChange={(text)=>{
                                    setFormPassword(text);
                                }}
                            />
                            <TextInput
                                inputType="password"
                                placeholder="Re-enter password"
                                label="PASSWORD CONFIRMATION"
                                inputValue={formPasswordConfirm}
                                onTextChange={(text)=>{
                                    setFormPasswordConfirm(text);
                                }}
                            />

                        </div>

                        <div className={'segment no-border'}>

                            <span className={'button'}
                                  onClick={()=>{
                                      setPending(true);

                                      if(formPassword !== formPasswordConfirm){
                                          alert('The passwords you entered do not match.')
                                          setPending(false);
                                      }

                                      let formData = new FormData();
                                      formData.append('_method','PUT');
                                      formData.append('name',formName);
                                      formData.append('surname',formLastName);
                                      formData.append('email',formEmail);
                                      formData.append('password',formPassword);
                                      formData.append('is_fake','0');

                                      UpdateProfile(formData).then(()=>{
                                          setPending(false);
                                          props.history.push('/profile');
                                      }).catch((error)=>{
                                          setPending(false);
                                          alert(error.message)
                                      });

                                  }}
                            >
                                Complete Registration
                            </span>

                        </div>
                    </div>

                </div>


            </div>

        </div>
    );

}
function mapStateToProps(state:IReduxPart){
    return{
        auth : state.auth
    }
}
const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CompleteRegister);
