import {ICurrentUser} from "../../types/CommonEntityTypes";
import {ACCESS_TOKEN, Runner} from "../../utils/ServiceTypes";

export interface IReduxAuth {
    currentUser: Runner;
    token: ACCESS_TOKEN;
}

interface IAction {
    type: string;
    payload : any;
}

const INIT_STATE : IReduxAuth = {
    currentUser: {
        id: 0,
        name: '',
        surname: '',
        email: '',
        profile : '',
        description : '',
        is_fake : 0
    },
    token: '',
};

const auth = (state:IReduxAuth = INIT_STATE, action : IAction) => {
    switch (action.type) {
        case 'SET_CURRENT_USER':
            return {
                ...state,
                currentUser: action.payload.user,
                token: action.payload.token,
            };
        case 'EMPTY_CURRENT_USER':
            return {
                ...state,
                currentUser: action.payload.user,
                token: action.payload.token,
            };
        default:
            return { ...state };
    }
};

export default auth;
