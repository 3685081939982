import React from 'react';
import SeriesEvent from "../../types/Types";
import "./FilterView.scss";
import CheckIcon from './assets/check.png';
import PageHeader from '../../../../sharedComponents/pageHeader/PageHeader';

interface IProps {
    events : SeriesEvent[];
    saveEvents : (events:SeriesEvent[], type:'region'|'date'|'search', searchInput : string)=>void;
}

type ShowStatus = 'all' | 'upcoming' | 'past';
interface IState {
    events : SeriesEvent[];
    showStatus : ShowStatus;
    groupType : 'date'|'search'|'region';
    searchInput : string;
}

class FilterView extends React.Component<IProps, IState>{

    constructor(props:IProps) {
        super(props);
        this.state = {
            events : props.events,
            showStatus : 'upcoming',
            groupType : 'date',
            searchInput : ''
        };

        this.changeShowStatus = this.changeShowStatus.bind(this);
        this.changeGroupType = this.changeGroupType.bind(this);
        this.changeSearchInput = this.changeSearchInput.bind(this);
        this.filterEventsWithRespectToStatus = this.filterEventsWithRespectToStatus.bind(this);

    }

    changeSearchInput(newInput : string){
        const component = this;
        this.setState({
            searchInput : newInput
        },()=>{
            if(
                component.state.searchInput.length > 2
            ){
                component.setState({
                    groupType : 'search'
                },()=>{
                    component.filterEventsWithRespectToStatus();
                });
            }else{
                component.setState({
                    groupType : 'date'
                },()=>{
                    component.filterEventsWithRespectToStatus();
                });
            }
        });
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if(prevProps.events.length !== this.props.events.length){
            this.filterEventsWithRespectToStatus();
        }
    }

    static getDerivedStateFromProps(nextProps:IProps){
        return{
            events : nextProps.events
        };
    }

    filterEventsWithRespectToStatus(){
        if(this.state.showStatus === 'all'){
            this.props.saveEvents(this.state.events, this.state.groupType,this.state.searchInput);
        }else if(this.state.showStatus === 'upcoming'){
            let temp : SeriesEvent[] = [];
            this.state.events.forEach((item)=>{
                let dateNow = new Date();
                let eventStartDate = new Date(item.start);
                if(
                    dateNow < eventStartDate
                ){
                    temp.push(item);
                }
            });
            this.props.saveEvents(temp,this.state.groupType,this.state.searchInput);
        }else{
            let temp : SeriesEvent[] = [];
            this.state.events.forEach((item)=>{
                let dateNow = new Date();
                let eventStartDate = new Date(item.start);
                if(
                    dateNow >= eventStartDate
                ){
                    temp.push(item);
                }
            });
            this.props.saveEvents(temp,this.state.groupType,this.state.searchInput);
        }
    }

    changeShowStatus(newStatus: ShowStatus){
        this.setState({
            showStatus : newStatus
        },()=>{
            this.filterEventsWithRespectToStatus();
        });
    }
    changeGroupType(newType: 'region'|'date'|'search'){
        this.setState({
            groupType : newType
        },()=>{
            this.filterEventsWithRespectToStatus();
        });
    }


    render() {
        const component = this;
        return (
            <div className={'series-runner-list-of-races-container'}>

                <div className="series-runner-list-of-races-wrapper">
                    <div className="search-race-header-container">
                        <PageHeader title='Search Races'/>
                    </div>
                    <div className="filters">
                        <div className="column left">
                            <div id="list-button-filter-by-region" className="filter-button" onClick={this.changeGroupType.bind(this,'region')}>Filter by region</div>
                            <div id="list-button-filter-by-date" className="filter-button" onClick={this.changeGroupType.bind(this,'date')}>filter by date</div>
                        </div>
                        <div className="column right">
                            <div className="place-filler"></div>
                            <input type="text" placeholder="Type here to search by title" id="input-for-search" onChange={(event)=>{
                                component.changeSearchInput(event.target.value)
                            }}/>
                            <div id="list-button-search-text" className="filter-button mobile-last" onClick={()=>{
                                component.changeGroupType('search');
                            }}>
                                search
                            </div>
                        </div>
                    </div>
                    <div className="filters second-row">

                        <div className={(this.state.showStatus==='all')?("column-flex active"):("column-flex")} onClick={this.changeShowStatus.bind(this,'all')}>
                            <div className="check">
                                <img src={CheckIcon}/>
                            </div>
                            <div className="text">Show All</div>
                        </div>

                        <div className={(this.state.showStatus==='upcoming')?("column-flex active"):("column-flex")} onClick={this.changeShowStatus.bind(this,'upcoming')}>
                            <div className="check">
                                <img src={CheckIcon}/>
                            </div>
                            <div className="text">Show Upcoming</div>
                        </div>

                        <div className={(this.state.showStatus==='past')?("column-flex active"):("column-flex")} onClick={this.changeShowStatus.bind(this,'past')}>
                            <div className="check">
                                <img src={CheckIcon}/>
                            </div>
                            <div className="text">Show Past</div>
                        </div>

                    </div>
                </div>
            </div>

        );
    }

}

export default FilterView;
