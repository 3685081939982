import React, {useState} from "react";
import "./MedalAccordion.scss";

import BodyText from "../bodyText/BodyText";

import Level1Medal from './assets/level1.jpeg';
import Level2Medal from './assets/level2.jpeg';
import Level3Medal from './assets/level3.jpeg';
import Level4Medal from './assets/level4.jpeg';
import Level5Medal from './assets/level5.jpeg';
import Level6Medal from './assets/level6.jpeg';
import Level7Medal from './assets/level7.jpeg';

import GreyText from "../greyText/GreyText";
import MedalAttendedRace from "../medalsAttendedRace/MedalAttendedRace";
import BlackArrow from './assets/arrowBlack.png';
import YellowArrow from './assets/arrowYellow.png';
import {Medal} from "../../utils/ServiceTypes";

interface IProps {
    medal : Medal;
}

function MedalAccordion(props: IProps) {

    const [open, setOpen] = useState(false);

    const attendedStyle = {
        opacity: open ? 1 : 0,
        transition: 'all .5s ease-in-out',
        height: open ? '100%' : '0'
    }
    return (
        <div className={'medal-accordion-container'}>
            <div className={'medal-accordion-border'}>
                <div className={'medal-accordion-header'} onClick={() => setOpen(!open)}>

                    <div>
                        {(props.medal.level === 1)?(<img src={Level1Medal} alt='Medal Level 1'/>):null}
                        {(props.medal.level === 2)?(<img src={Level2Medal} alt='Medal Level 2'/>):null}
                        {(props.medal.level === 3)?(<img src={Level3Medal} alt='Medal Level 3'/>):null}
                        {(props.medal.level === 4)?(<img src={Level4Medal} alt='Medal Level 4'/>):null}
                        {(props.medal.level === 5)?(<img src={Level5Medal} alt='Medal Level 5'/>):null}
                        {(props.medal.level === 6)?(<img src={Level6Medal} alt='Medal Level 6'/>):null}
                        {(props.medal.level === 7)?(<img src={Level7Medal} alt='Medal Level 7'/>):null}
                    </div>
                    <BodyText isBold>Level {props.medal.level}</BodyText>
                    <BodyText isBold={false}>{props.medal.totalMiles} Miles Completed</BodyText>
                    <div className={'slash'}>
                        /
                    </div>
                    <div className={'medal-accordion-date'}>
                        <GreyText isBold>{'Jan 1, '+props.medal.year+' - Dec 31, '+props.medal.year}</GreyText>
                    </div>
                    <div>
                        {open ?
                            <div className={'yellow-arrow'}>
                                <img src={YellowArrow} alt="yellow-arrow"/>
                            </div>
                            :
                            <div className={'black-arrow'}>
                                <img src={BlackArrow} alt="black-arrow"/>
                            </div>
                        }
                    </div>
                </div>
                <div className={open ? 'open-accordion' : 'close-accordion'} style={attendedStyle}>
                    <MedalAttendedRace medal={props.medal}/>
                </div>
            </div>
        </div>
    );

}

export default MedalAccordion;
