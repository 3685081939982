export default function PutPrePaymentRegisterRedirect(eventId : string){
    return({
        type : 'CHANGE_PRE_PAYMENT_REGISTER_REDIRECT',
        payload : {
            isActive : true,
            eventId : eventId
        }
    });
}
export function ClearPrePaymentRegisterRedirect(){
    return({
        type : 'CHANGE_PRE_PAYMENT_REGISTER_REDIRECT',
        payload : {
            isActive : false,
            eventId : '0'
        }
    });
}
