import React from "react";
import './RaceButtonInside.scss';
import ArrowBlack from './assets/arrow.png';
import ArrowYellow from './assets/yellow-arrow.png';
import Frame from './assets/frame.png';
import {NavLink} from "react-router-dom";
import {Event} from "../../utils/ServiceTypes";
import phpTimeToJSTime, {getDayOfWeek, getMonth, getRightTime, getTheDate, getTheYear} from "../../utils/Time";

interface IProps {
    item: Event;
}

interface IState {
    active: boolean,
    show: boolean,
    className: string
}

class RaceButtonInside extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            active: false,
            show: true,
            className: ''
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            active: !this.state.active,
            show: !this.state.show,
            className: 'active'
        })
    }


    render() {

        const startHour = phpTimeToJSTime(this.props.item.start);
        const endHour = phpTimeToJSTime(this.props.item.end);

        const activeClass = this.state.active ? 'active' : 'inactive';

        const cardStyle = {
            opacity: this.state.show ? 0 : 1,
            transition: 'all .3s ease-in',
            height: this.state.show ? '0' : 'auto',
            padding: this.state.show ? '0' : '20px 0'
        }

        let isCompleted = false;
        if(startHour < new Date()){
            isCompleted = true;
        }


        return (
            <div className={activeClass + " wrapper flexbox flex-column"}
            >
                <div className="race-button-top"
                     onClick={this.toggle}
                >
                    <div className="race-logo">
                        <img src={this.props.item.logo} alt=""/>
                    </div>
                    <div className="race-status">
                        {(isCompleted)?(<span className="completed-badge">Event has finished</span>):('')}
                    </div>
                    <div className="race-title">
                        {this.props.item.title}
                    </div>
                    <div className="slash">
                        /
                    </div>
                    <div className="date">
                        {getDayOfWeek(startHour)}, {getMonth(startHour)} {getTheDate(startHour)}, {getTheYear(startHour)}
                    </div>
                    <div className="black-arrow">
                        <img src={ArrowBlack} alt="black-arrow"/>
                    </div>
                    <div className="yellow-arrow">
                        <img src={ArrowYellow} alt="yellow-arrow"/>
                    </div>
                </div>
                <div style={cardStyle} className="race-button-bottom">
                    <div className="left">
                        <div className="top">
                            Time:
                        </div>
                        <div className="middle">
                            ({getDayOfWeek(startHour)}, {getMonth(startHour)} {getTheDate(startHour)}, {getTheYear(startHour)} )
                        </div>
                        <div className="bottom">
                            {getRightTime(startHour)} - {getRightTime(endHour)}
                        </div>
                    </div>
                    <div className="middle">
                        <div className="top">
                            Location:
                        </div>
                        <div className="middle">
                            {this.props.item.location.address}
                        </div>
                    </div>
                    <div className="right">
                        {/*<div className="registered">
                            Registered
                            <img src={Frame} alt=""/>
                        </div>*/}
                        <NavLink to={'/event-info/' + this.props.item.id} className="more-information">
                            MORE INFORMATION
                        </NavLink>
                    </div>

                </div>

            </div>
        );
    }
}

export default RaceButtonInside;
