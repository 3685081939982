import {
    Runner,
    Event,
    IResponseGetFavorite,
    IResponseGetRunnerEvents,
    Medal,
    IParametersPostPayment
} from "../ServiceTypes";
import {ServiceCall} from "../ServiceCall";
import store, {dispatchWithPromise} from "../../redux";
import SetCurrentUser from "../../redux/auth/actions";
import {ExtractFavoriteIDs} from "../Parser";
import SetCurrentFavorites, {
    SetCurrentFavoritesWithAddition,
    SetCurrentFavoritesWithSubtraction
} from "../../redux/favorites/actions";
import imageCompression from "browser-image-compression";

type IPromiseGetProfileDetails = Runner;

export default function GetProfileDetails() {
    return new Promise<IPromiseGetProfileDetails>((resolve, reject) => {

        ServiceCall('GET', 'user', '').then((response: any) => {
            const result: Runner = response.data;

            if(result){

                if(result.surname === null){
                    result.surname = '';
                }
                if(result.description === null){
                    result.description = '';
                }

                dispatchWithPromise(
                    SetCurrentUser(
                        result,
                        store.getState().auth.token
                    )
                ).then(()=>{
                    resolve(result);
                });

            }else{
                reject();
            }

        }).catch(() => {
            reject();
        });

    });
}

interface IPromiseGetRegisteredEvents {
    events: Event[];
};


export function GetRegisteredEvents(upcoming:boolean=false) {

    return new Promise<IPromiseGetRegisteredEvents>((resolve, reject) => {

        ServiceCall('GET', 'runner/events/all', '').then((response: any) => {

            const result: IResponseGetRunnerEvents = response.data;


            if(result.data.length > 0){

                const dateNow = Date.now();
                let returnResults :Event[] = []
                if(upcoming){
                    result.data.forEach((event)=>{
                        if(new Date(event.start).getTime() > dateNow){
                            returnResults.push(event);
                        }
                    })
                }else{
                    returnResults = result.data;
                }

                resolve({
                    events: returnResults
                });
            }else{
                resolve({
                    events: []
                });
            }

        }).catch(() => {
            reject();
        });

    });

}

type IPromiseGetFavoriteEvents = {
    events: Event[];
};

export function GetFavoriteEvents() {

    return new Promise<IPromiseGetFavoriteEvents>((resolve, reject) => {
        ServiceCall('GET', 'runner/events/favorites', '').then((response: any) => {
            const result: IResponseGetFavorite = response.data;
            if(result.data){
                store.dispatch(
                    SetCurrentFavorites(ExtractFavoriteIDs(result.data))
                );
                resolve({
                    events: result.data
                });
            }else{
                reject();
            }
        }).catch(() => {
            reject();
        });
    });

}

export function AddToFavorites(eventId : number){
    return new Promise((resolve, reject)=>{
        ServiceCall('POST','runner/events/'+eventId+'/favorite','').then(()=>{
            store.dispatch(SetCurrentFavoritesWithAddition(eventId));
            resolve({});
        }).catch(()=>{
            reject({});
        });
    });
}

export function RemoveFromFavorites(eventId : number){
    return new Promise((resolve, reject)=>{
        ServiceCall('POST','runner/events/'+eventId+'/unfavorite','').then(()=>{
            store.dispatch(SetCurrentFavoritesWithSubtraction(eventId));
            resolve({});
        }).catch(()=>{
            reject({});
        });
    });
}

type IPromiseGetEventInfo = Event;

export function GetEventInfo(eventId:string = '0') {

    return new Promise<IPromiseGetEventInfo>((resolve, reject) => {

        ServiceCall('GET', `events/${eventId}`, '').then((response: any) => {
            const result: Event = response.data;

            resolve(result);
        }).catch(() => {
            reject();
        })
    })
}


type IPromiseUpdateProfile = Runner;

export function UpdateProfile(formData:any){

    return new Promise<IPromiseUpdateProfile>((resolve,reject)=>{

        ServiceCall('POST','user',formData).then((response:any)=>{
            const result: Runner = response.data;


            if(result){

                dispatchWithPromise(
                    SetCurrentUser(
                        result,
                        store.getState().auth.token
                    )
                ).then(()=>{
                    resolve(result);
                });

            }else{
                console.log('HERE THEN');
                reject('');
            }

        }).catch((error)=>{

            if(
                error.response.data &&
                error.response.data.errors &&
                error.response.data.errors.email &&
                error.response.data.errors.email.length > 0
            ){
                let temp = '';
                error.response.data.errors.email.forEach((item:string)=>{
                    temp += item+' ';
                });
                reject({
                    message : temp
                });
            }else{
                let message = '';
                if(
                    error && error.response && error.response.data && error.response.data.errors
                ){
                    if(error.response.data.errors.name){
                        message += 'Please enter valid name. ';
                    }
                    if(error.response.data.errors.surname){
                        message += 'Please enter valid lastname. ';
                    }
                    if(error.response.data.errors.description){
                        message += 'Please enter valid description text. ';
                    }
                }
                reject(message);
                reject({
                    message : message
                });
            }

        });

    });

}

type IPromiseChangePassword = {
    success : boolean
};

export function ChangePassword(password:string){

    return new Promise<IPromiseChangePassword>((resolve,reject)=>{

        ServiceCall('POST','user', {
            password : password,
            '_method' : 'PUT'
        }).then((response:any)=>{
            const result: Runner = response.data;
            if(
                result &&
                result.email
            ){
                resolve({
                    success : true
                });
            }else{
                reject();
            }

        }).catch(()=>{

            reject();

        });

    });

}


export function SendExternalPaymentReceipt(eventId:number, selectedFile:any){
    return new Promise((resolve, reject)=>{

        let formData = new FormData();
        formData.append('receipt',selectedFile);

        ServiceCall('POST','runner/events/'+eventId+'/payment/external',formData).then((response:any)=>{
            const result = response.data;
            if(result){
                resolve({});
            }else{
                reject();
            }
        }).catch(()=>{
            reject();
        });

    });
}



export function MakePayment (eventId: number, parameters : IParametersPostPayment){

    return new Promise((resolve,reject)=>{

        ServiceCall('POST','runner/events/'+eventId +'/payment',parameters).then((response : any)=>{
            const result = response.data;
            if(
                result &&
                typeof result !== 'string' &&
                result.event_id === eventId
            ){
                resolve({});
            }else {
                reject({
                    message : (typeof result === 'string')?(result):('An error is occurred!')
                });
            }

        }).catch(()=>{
            reject({
                message : 'An error is occurred!'
            });
        });

    });

}

type IPromiseGetRunnerResults = Event[];
type IResponseGetRunnerEventsResults = {
    data : Event[];
};

export function GetRunnerResults(){

    return new Promise<IPromiseGetRunnerResults>((resolve, reject)=>{
        ServiceCall('GET','runner/events/results','').then((response:any)=>{
            const result : IResponseGetRunnerEventsResults= response.data;
            if(result.data){
                resolve(result.data);
            }else{
                reject();
            }
        }).catch((error:any)=>{
            reject();
        });
    })
}

type IPromiseGetRunnerMedals = {
    medals : Medal[]
};
export function GetRunnerMedals(){

    return new Promise<IPromiseGetRunnerMedals>((resolve, reject)=>{
        ServiceCall('GET','runner/events/medals','').then((response:any)=>{
            const result : any = response.data;
            if(Array.isArray(result)){
                resolve({
                    medals : result.filter((item)=>{
                        return item.level > 0;
                    })
                });
            }else{
                reject();
            }
        }).catch((error:any)=>{
            reject();
        });
    })
}

export function SubmitAResult(
    eventId : number = 0,
    miles : number = 0
){

    return new Promise((resolve, reject)=>{
        ServiceCall('POST','runner/events/'+eventId+'/results',{
            miles : miles
        }).then((response:any)=>{
            if(
                response &&
                response.data &&
                response.data.event_id &&
                response.data.event_id === eventId
            ){
                resolve({});
            }else{
                reject();
            }
        }).catch((error)=>{
            reject();
        });
    });

}
