import React, {ReactElement} from "react";
import './PageHeader.scss'

type IProps = {
    title: string;
    buttonElement?: ReactElement | null;
};

const PageHeader = ({
                        title = '',
                        buttonElement = null,
                    }: IProps) => {
    return (
        <div className={'login-header-container'}>

            <div className={'login-header'}>
                <div className="page-title">
                    {title}
                </div>
            </div>

            {(buttonElement !== null) ? buttonElement : null}

        </div>
    );
}

export default PageHeader;
