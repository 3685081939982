import React from "react";
import StarIcon from "../eventInfoInside/assets/star.png";
import StarIcon2 from "../eventInfoInside/assets/star-2.png";
import StarIcon3 from "../eventInfoInside/assets/star-3.png";
import {IReduxPart} from "../../redux";
import {connect, ConnectedProps} from "react-redux";
import {AddToFavorites, RemoveFromFavorites} from "../../utils/api/InnerPageServices";

type IProps = PropsFromRedux & {
    eventId : number
};

const FavoriteButton = (props: IProps)=>{

    return(
        <div
            className={(
                props.favorites.favorites.includes(props.eventId)
            )?(
                'main-button transparent-button favorite-button active'
            ):(
                'main-button transparent-button favorite-button inactive'
            )}
            onClick={()=>{
                if(props.favorites.favorites.includes(props.eventId)){
                    RemoveFromFavorites(props.eventId).then();
                }else{
                    AddToFavorites(props.eventId).then();
                }
            }}
        >
            <img src={StarIcon} className={'first'}/>
            <img src={StarIcon2} className={'second'}/>
            <img src={StarIcon3} className={'third'}/>
        </div>
    );
}

function mapStateToProps(state:IReduxPart){
    return{
        favorites : state.favorites
    }
}
const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;


export default connector(FavoriteButton);
