import React from "react";
import "./TextInput.scss"

type IProps = {
    label:string;
    placeholder:string;
    inputType:'text'|'password';
    inputValue:string;
    onTextChange: (text:string)=>void;
    disabled?:boolean;
};

const TextInput = ({
    label = '',
    inputValue = '',
    placeholder = '',
    inputType = 'text',
    disabled = false,
    onTextChange = (text:string)=>{}
}:IProps)=>{

    return(
        <React.Fragment>
            {(label !== '')?(
                <div className={'custom-text-input-label'}>
                    {label}
                </div>
            ):null}
            <div className={'custom-text-input'}>
                <input
                    type={inputType}
                    value={inputValue}
                    onChange={(event)=>{
                        onTextChange(event.target.value);
                    }}
                    disabled={disabled}
                    placeholder={placeholder}
                />
            </div>

        </React.Fragment>
    );

}

export default TextInput;
