import React from "react";
import './BackgroundContainer.scss';

interface IProps {
    img: string;
}

function BackgroundContainer({img = ''}: IProps) {
    return (
        <div className={'yellow-area'}>
            <div className={'background-area'} style={{backgroundImage: `url(${img})`}}>

            </div>
        </div>
    );

}

export default BackgroundContainer;
