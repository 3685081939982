export interface IReduxTemporaryAppState {
    prePaymentRegisterRedirect : {
        isActive : boolean;
        eventId : string;
    }
}

interface IAction {
    type: string;
    payload : any;
}

const INIT_STATE : IReduxTemporaryAppState = {
    prePaymentRegisterRedirect : {
        isActive : false,
        eventId : '0'
    }
};

const temporaryAppState = (state:IReduxTemporaryAppState = INIT_STATE, action : IAction) => {
    switch (action.type) {
        case 'CHANGE_PRE_PAYMENT_REGISTER_REDIRECT':
            return {
                ...state,
                prePaymentRegisterRedirect: action.payload,
            };
        default:
            return { ...state };
    }
};

export default temporaryAppState;
