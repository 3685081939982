import React from "react";
import "./MedalsView.scss";

import Header from "../../sharedComponents/header/Header";
import MedalAccordion from "../../sharedComponents/medalAccordion/MedalAccordion";
import SideMenu from "../../sharedComponents/sideMenu/SideMenu";
import Button from "../../sharedComponents/buttonTheme/Button";
import PageHeader from "../../sharedComponents/pageHeader/PageHeader";
import {Medal} from "../../utils/ServiceTypes";
import {GetRunnerMedals} from "../../utils/api/InnerPageServices";
import LoadingIndicator from "../../sharedComponents/loading/LoadingIndicator";
import SimpleButton from "../../sharedComponents/simpleButton/SimpleButton";

type IState = {
    isPending : boolean;
    medals : Medal[];
};

class MedalsView extends React.Component<any, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            isPending : true,
            medals : []
        };

        this.getMedals = this.getMedals.bind(this);

    }

    getMedals(){
        const component = this;
        GetRunnerMedals().then((result)=>{
            component.setState({
                medals : result.medals,
                isPending : false
            });
        }).catch(()=>{
            component.setState({
                medals : [],
                isPending : false
            });
        });
    }

    componentDidMount() {
        this.getMedals();
    }

    render() {
        return (
            <div className={'medals-container'}>
                <div className={'main-body-medals'}>
                    <div className={'medals-body'}>
                        <div className={'header-area'}>
                            <PageHeader title='Medals'/>
                        </div>
                        <div className={'accordion-area'}>

                            {(this.state.isPending)?(
                                <LoadingIndicator/>
                            ):(
                                (this.state.medals.length > 0)?(
                                    <React.Fragment>

                                        {this.state.medals.map((item)=>{
                                            return(
                                                <MedalAccordion
                                                    medal={item}
                                                />
                                            );
                                        })}

                                    </React.Fragment>
                                ):(
                                    <div className="my-profile-container">
                                        <div className="my-profile-row direction-column">
                                            <div className="race-button-container">

                                                <h3 className={'warningMessage'}>You have no medals.</h3>

                                                <SimpleButton title={'Find a Race Now'} link={'search'}/>

                                            </div>
                                        </div>

                                    </div>
                                )
                            )}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MedalsView;
