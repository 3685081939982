import React from "react";
import "./Header.scss";

interface IProps {
    children: string;
}

function Header({
                    children = ''
                }: IProps) {
    return (
        <div className={'main-header'}>
            {children}
        </div>
    );

}

export default Header;
