import React from "react";
import "./SideMenu.scss";
import logo from './assets/logo@2x.png';
import SideMenuButton from "../sideMenuButton/SideMenuButton";
import {NavLink} from "react-router-dom";
import LogoImg from '../../screens/login/assets/logo@2x.png';
import Logo from "../logo/Logo";
import {LogoutARunner} from "../../utils/api/OuterPageServies";
import {IReduxPart} from "../../redux";
import {connect, ConnectedProps} from "react-redux";
import BlackArrow from './assets/arrow@2x.png';
import {GetFavoriteEvents} from "../../utils/api/InnerPageServices";
import {mainSiteURL} from "../../utils/ServiceCall";
import {IsFakeUser} from "../../utils/ProtectedRoute";

type IProps = PropsFromRedux & {
    history: {
        push: any;
    }
};
type IState = {
    active: boolean;
};

class SideMenu extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            active: false
        };
        this.hamburgerMenu = this.hamburgerMenu.bind(this);
        this.logOut = this.logOut.bind(this);
    }

    componentDidMount() {
        GetFavoriteEvents().then();
    }

    hamburgerMenu() {
        this.setState({
            active: !this.state.active,
        })
    }

    logOut() {

        LogoutARunner(this.props.history);

    }

    render() {

        const currentUser = this.props.auth.currentUser;

        const activeClassName = this.state.active ? 'active' : '';
        const openClassName = this.state.active ? 'open' : '';

        return (
            <div className={'side-menu'}>
                <div className="side-menu-container">
                    <div className="side-menu-top">
                        <div className="side-menu-wrapper pdb-65">
                            <div className={activeClassName + " button_container"} id="toggle">

                                <div className="hamburger" onClick={this.hamburgerMenu}>
                                    <span className="top"/>
                                    <span className="middle"/>
                                    <span className="bottom"/>
                                </div>

                            </div>
                            <div className={openClassName + " overlay"} id="overlay">
                                <div className="logo">
                                    <NavLink to={'/profile'}>
                                        <img src={logo} alt="logo"/>
                                    </NavLink>
                                </div>
                                <div className="menu-container">
                                    <div>
                                        {(currentUser.is_fake === 1)?(
                                            <SideMenuButton hamburgerMenu={this.hamburgerMenu} title={'Complete Registration'}
                                                            link={'register-complete'} exact/>
                                        ):(
                                            <React.Fragment>
                                                <SideMenuButton hamburgerMenu={this.hamburgerMenu} title={'My profile'}
                                                                link={'profile'} exact/>
                                                <SideMenuButton hamburgerMenu={this.hamburgerMenu} title={'Search Races'}
                                                                link={'search'}/>
                                                <SideMenuButton hamburgerMenu={this.hamburgerMenu} title={'Upcoming Races'}
                                                                link={'upcoming-races'}/>
                                                <SideMenuButton hamburgerMenu={this.hamburgerMenu} title={'Favorites'}
                                                                link={'favorites'}/>
                                                <SideMenuButton hamburgerMenu={this.hamburgerMenu} title={'Medals'}
                                                                link={'medals'}/>
                                                <SideMenuButton hamburgerMenu={this.hamburgerMenu} title={'Results'}
                                                                link={'results'}/>
                                                <SideMenuButton hamburgerMenu={this.hamburgerMenu} title={'Edit Profile'}
                                                                link={'profile/edit'}/>
                                            </React.Fragment>
                                        )}
                                        <a href={mainSiteURL} target='_blank'
                                           className="series-web-link">
                                            <p>Series Runner</p>
                                            <img src={BlackArrow} alt="black-arrow"/>
                                        </a>
                                    </div>
                                    <div className="side-menu-bottom">
                                        <div className="log-out">
                                            <p onClick={this.logOut}>
                                                Log out
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="logo">
                                <NavLink to={'/profile'}>
                                    <Logo LogoImg={LogoImg} logoWidth={180}/>
                                </NavLink>
                            </div>
                            {(
                                currentUser.is_fake !== 1 &&
                                currentUser.profile &&
                                currentUser.profile.charAt(currentUser.profile.length - 1) !== '/'
                            )?(
                                <div className="profile-picture-mobile">
                                    <div style={{backgroundImage:'url('+currentUser.profile+')'}}/>
                                </div>
                            ):null}
                        </div>
                        {(currentUser.is_fake === 1)?null:(
                        <div className="side-menu-wrapper mobile-hidden grey-bg pdt-50 pdb-20">
                            {(
                                currentUser.profile &&
                                currentUser.profile.charAt(currentUser.profile.length - 1) !== '/'
                            )?(
                                <div className="profile-picture">
                                    <div className={'image'}>
                                        <span style={{backgroundImage:'url('+currentUser.profile+')'}}/>
                                    </div>
                                </div>
                            ):null}

                                <div className="profile-title" style={{borderBottom:'none'}}>
                                    <div className="name">
                                        {(currentUser.name)?(currentUser.name+' '):''}{(currentUser.surname)?(currentUser.surname):''}
                                    </div>
                                    <div className="email">
                                        <a href={'mailto:' + currentUser.email}>
                                            {currentUser.email}
                                        </a>
                                    </div>
                                </div>
                        </div>
                        )}
                        <div className="side-menu-wrapper mobile-hidden pd-0">

                            {(currentUser.is_fake === 1)?(
                                <SideMenuButton hamburgerMenu={this.hamburgerMenu} title={'Complete Registration'}
                                                link={'register-complete'} exact/>
                            ):(
                                <React.Fragment>
                                    <SideMenuButton hamburgerMenu={this.hamburgerMenu} title={'My profile'} link={'profile'}
                                                    exact={false}/>
                                    <SideMenuButton hamburgerMenu={this.hamburgerMenu} title={'Search Races'} link={'search'}/>
                                    <SideMenuButton hamburgerMenu={this.hamburgerMenu} title={'Upcoming Races'}
                                                    link={'upcoming-races'}/>
                                    <SideMenuButton hamburgerMenu={this.hamburgerMenu} title={'Favorites'} link={'favorites'}/>
                                    <SideMenuButton hamburgerMenu={this.hamburgerMenu} title={'Medals'} link={'medals'}/>
                                    <SideMenuButton hamburgerMenu={this.hamburgerMenu} title={'Results'} link={'results'}/>
                                </React.Fragment>
                            )}
                            <a href={mainSiteURL} target='_blank'>
                                <p>Series Runner</p>
                                <img src={BlackArrow} alt="black-arrow"/>
                            </a>
                        </div>
                    </div>
                    {(!IsFakeUser())?(
                        <div className="side-menu-bottom">
                            <div className="log-out">
                                <p onClick={this.logOut}>
                                    Log out
                                </p>
                            </div>
                        </div>
                    ):null}
                </div>
            </div>
        );
    }

}

function mapStateToProps(state: IReduxPart) {
    return {
        auth: state.auth
    }
}

const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;


export default connector(SideMenu);
