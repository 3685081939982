import React, {useState} from "react";
import {Event} from "../../../utils/ServiceTypes";
import DocumentIcon from "../assets/document.png";
import {IReduxPart} from "../../../redux";
import {connect, ConnectedProps} from "react-redux";
import LoadingIndicator from "../../../sharedComponents/loading/LoadingIndicator";
import BasicDropzone from "../../../sharedComponents/input/BasicDropzone";
import {SendExternalPaymentReceipt} from "../../../utils/api/InnerPageServices";

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];


function getRightTime(hour: Date) {

    let minutes, fullHour: string;

    if (hour.getMinutes() == 0) {
        minutes = hour.getMinutes() + '0';
    } else {
        minutes = hour.getMinutes().toString();
    }
    if (hour.getHours() < 12) {
        fullHour = hour.getHours() + ':' + minutes + ' AM';
    } else {
        fullHour = hour.getHours() + ':' + minutes + ' PM';
    }

    return fullHour;
}

type IProps = PropsFromRedux & {
    event : Event;
    history : any;
    backButtonFunction : ()=>void;
    continueButtonFunction : (status:any, message:string)=>void;
};

const Step4BuyTicket = ({
    event,
    history,
    backButtonFunction,
                            continueButtonFunction,
    ...props
}:IProps)=>{


    const [isPending,setIsPending] = useState<boolean>(false);


    const startHour = new Date((event !== null) ? (event.start.replaceAll('.000000Z','')) : (0));
    const endHour = new Date((event !== null) ? (event.end.replaceAll('.000000Z','')) : (0));

    const [selectedFile, setSelectedFile] = useState<any>(null);


    if(isPending){
        return (
            <LoadingIndicator/>
        );
    }

    return(
        <div className={'payment-container'}>
            <div className={'content'} style={{paddingBottom:0}}>

                <div className={'column'}>


                    <div className={'payment-card'}>
                        <div className={'segment no-background'}>

                                <span className={'title'}>
                                    Registration Receipt
                                </span>
                        </div>
                        <div className={'segment'}>


                            <BasicDropzone onDrop={(files)=>{
                                setSelectedFile(files[0]);

                            }}/>

                            {(selectedFile !== null)?(
                                <div className={'file-preview'}>
                                    <p>
                                        You are ready to submit!
                                    </p>
                                    {(selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png')?(
                                        <img src={URL.createObjectURL(selectedFile)}/>
                                    ):(
                                        <React.Fragment>
                                            <p>
                                                You have selected: <span>{selectedFile.name}</span>
                                            </p>
                                            <img src={DocumentIcon} className={'small'}/>
                                        </React.Fragment>
                                    )}
                                </div>
                            ):null}


                        </div>
                    </div>


                </div>

                <div className={'column'}>

                    <div className={'payment-card'}>

                        <div className={'segment no-background'}>

                                <span className={'title'}>
                                    Review Information
                                </span>

                        </div>

                        <div className={'segment'}>

                            <div className={'info'}>
                                <div>
                                    <span>
                                        Event Name
                                    </span>
                                    <p>
                                        {event.title}
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className={'segment'}>

                            <div className={'info'}>
                                <div>
                                        <span>
                                            DATE
                                        </span>
                                    <p>
                                        {daysOfWeek[startHour.getDay()]}, {startHour.getDate()} {monthNames[startHour.getMonth()]}
                                    </p>
                                </div>
                                <div>
                                        <span>
                                            TIME
                                        </span>
                                    <p>
                                        {getRightTime(startHour)} - {getRightTime(endHour)}
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className={'segment'}>

                            <div className={'info'}>
                                <div>
                                        <span>
                                            Location
                                        </span>
                                    <p>
                                        {event.location.state}
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className={'segment'}>

                            <div className={'info'}>
                                <div>
                                        <span>
                                            Address
                                        </span>
                                    <p>
                                        {event.location.address}
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>


                    <div className={'payment-card'}>
                        <div className={'segment no-border no-padding'} style={{border:'none'}}>

                            <span className={'button full-width special'} onClick={()=>{

                                if(selectedFile === null){
                                    alert('Please select your receipt.')
                                    return null;
                                }

                                setIsPending(true);

                                SendExternalPaymentReceipt(
                                    event.id,
                                    selectedFile
                                ).then(()=>{
                                    setIsPending(false);
                                    continueButtonFunction('success','');
                                }).catch(()=>{
                                    alert('An error is occurred.');
                                    setIsPending(false);
                                });

                            }}>
                                SUBMIT
                            </span>

                        </div>
                    </div>

                    <div className={'payment-card'}>
                        <div className={'segment no-border no-padding'} style={{border:'none'}}>

                            <span className={'button full-width no-special'} onClick={()=>{
                                backButtonFunction();
                            }} style={{color:'#999999', borderColor:'#999999'}}>
                                Back to Step 1
                            </span>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}

function mapStateToProps(state:IReduxPart){
    return{
        auth : state.auth,
        persisted : state.persisted
    }
}
const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Step4BuyTicket);
