import React from "react";
import "./ForgotPassword.scss";
import InputArea from "../../sharedComponents/inputArea/InputArea";
import Button from "../../sharedComponents/buttonTheme/Button";
import OuterPages from "../../sharedComponents/outerPagesLayout/OuterPagesLayout";
import TextSmall from "../../sharedComponents/textSmall/TextSmall";
import { NavLink } from "react-router-dom";
import OuterPagesButton from "../../sharedComponents/outerPagesButton/OuterPagesButton";
import {SendForgotPasswordMail} from "../../utils/api/OuterPageServies";

interface IProps {

}
interface IState {
    emailField: string;
}

class ForgotPassword extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            emailField: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);

    }

    handleSubmit = (event:any) => {
        event.preventDefault();

        SendForgotPasswordMail(this.state.emailField).then((result:any)=>{
            if(result && result.message){
                alert(result.message);
            }else{
                alert('An error occurred!');
            }
        }).catch(()=>{
            alert('An error occurred!');
        });


    }
    handleEmailChange = (event: any) => {
        this.setState({
            emailField: event.target.value
        });
    }

    render() {
        return (
            <div className={'forgot-password-page'}>
                <OuterPages headerTitle={'FORGOT PASSWORD'} bgImg='/image/bg-forgotpass.jpg'>
                    <div className={'form-area'}>
                        <form onSubmit={this.handleSubmit}>
                            <InputArea
                                inputType="email"
                                placeholder="Enter email address"
                                children="EMAIL"
                                inputValue={this.state.emailField}
                                onChangeInputValue={this.handleEmailChange}
                            />

                            <OuterPagesButton blackButton type={'submit'}>RESET PASSWORD</OuterPagesButton>
                        </form>
                    </div>
                    <div className={'back-to-sign'}>
                        <NavLink to={'login'} style={{textDecoration: "none", color: '#000000'}}>
                            <TextSmall>Back to log in</TextSmall>
                            <hr/>
                        </NavLink>

                    </div>
                </OuterPages>
            </div>
        );
    }

}

export default ForgotPassword;
