import React from "react";
import './Logo.scss';

interface IProps {
    LogoImg: string;
    logoWidth: number;
}

function Logo({
                  LogoImg = '',
                  logoWidth = 270
}: IProps)
{
    return (
        <img src={LogoImg} alt="logo" style={{maxWidth:logoWidth+'px', width:'100%'}} className={'logo'}/>
    );
}

export default Logo;
