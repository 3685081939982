import {
    EmptyLatestBuyTicketStep2Object,
    EmptyLatestBuyTicketStep3Object,
    LatestBuyTicketStep2Object,
    LatestBuyTicketStep3Object
} from "./actions";

export interface IReduxPersisted {
    latestBuyTicketStep2Object : LatestBuyTicketStep2Object;
    latestBuyTicketStep3Object : LatestBuyTicketStep3Object;
}

interface IAction {
    type: string;
    payload : any;
}

const localStoreContent = localStorage.getItem('mySeriesRunnerLatestPaymentInfo1');
const localStoreContent2 = localStorage.getItem('mySeriesRunnerLatestPaymentInfo2');
let parsedContent = EmptyLatestBuyTicketStep2Object;
if(
    localStoreContent !== null &&
    typeof localStoreContent === 'string'
){
    parsedContent = JSON.parse(localStoreContent);
}
let parsedContent2 = EmptyLatestBuyTicketStep3Object;
if(
    localStoreContent2 !== null &&
    typeof localStoreContent2 === 'string'
){
    parsedContent2 = JSON.parse(localStoreContent2);
}

let INIT_STATE : IReduxPersisted = {
    latestBuyTicketStep2Object : parsedContent,
    latestBuyTicketStep3Object : parsedContent2
};



const persisted = (state:IReduxPersisted = INIT_STATE, action : IAction) => {
    switch (action.type) {
        case 'SET_LATEST_BUY_TICKET_STEP_2_OBJECT':
            return {
                ...state,
                latestBuyTicketStep2Object: action.payload,
            };
        case 'SET_LATEST_BUY_TICKET_STEP_3_OBJECT':
            return {
                ...state,
                latestBuyTicketStep3Object: action.payload,
            };
        default:
            return { ...state };
    }
};

export default persisted;
