import {IsAuthorised} from "./ProtectedRoute";
import {dispatchWithPromise} from "../redux";
import PutPrePaymentRegisterRedirect from "../redux/temporary-app-state/actions";
import {RegisterAFakeRunner} from "./api/OuterPageServies";

export function RedirectToPayment(eventId: string = '0', history : any = null){
    history.push('/payment/'+eventId);
}

export function RedirectToPrePayment(eventId: string = '0', history : any = null){
    history.push('/pre-payment/'+eventId);
}

export function RedirectPrePaymentRegister(eventId: string = '0', history : any = null){
    dispatchWithPromise(
        PutPrePaymentRegisterRedirect(
            eventId
        )
    ).then(()=>{
        history.push('/register/');
    });
}

export function RedirectPaymentWithFakeRegister(eventId: string = '0', history : any = null){
    return new Promise((resolve,reject)=>{
        RegisterAFakeRunner().then(()=>{
            resolve({});
        }).catch((result)=>{
            alert(result.message);
            reject();
        });
    });
}

export default function BuyTicketButtonClicked(eventId: string = '0', history: any){

    if(IsAuthorised(true)){
        RedirectToPayment(eventId, history);
    }else{
        RedirectToPrePayment(eventId, history);
    }

}
