import React from "react";
import {NavLink} from "react-router-dom";
import './SideMenuButton.scss';
import BlackArrow from './assets/arrow@2x.png';
import WhiteArrow from './assets/arrow_up@2x.png';

type IProps = {
    title: string;
    link?: string;
    exact?: boolean;
    hamburgerMenu: any
};

class SideMenuButton extends React.Component<IProps,any>{

    constructor(props: any) {
        super(props);
        this.state = {
        }
    }


    render() {

        return (
            <div className={'side-menu-button-container'}>
                <NavLink onClick={this.props.hamburgerMenu} activeClassName={'active'} to={'/'+this.props.link} className="side-menu-button-wrapper" exact={this.props.exact}>
                    <div className="title">
                        {this.props.title}
                    </div>
                    <div className="black-arrow">
                        <img src={BlackArrow} alt=""/>
                    </div>
                    <div className="white-arrow">
                        <img src={WhiteArrow} alt=""/>
                    </div>
                </NavLink>

            </div>
        );
    }
}

export default SideMenuButton;
