const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];


export default function phpTimeToJSTime(phpTime: string = '') {
    let returnTemp = new Date(new Date(phpTime.replace('.000000Z', '')).getTime());
    return returnTemp;
}

export function getRightTime(hour: Date) {


    let minutes, fullHour: string;

    if (hour.getMinutes() === 0) {
        minutes = hour.getMinutes() + '0';
    } else {
        minutes = hour.getMinutes().toString();
    }
    if (hour.getHours() < 12) {
        fullHour = hour.getHours() + ':' + minutes + ' AM';
    } else {
        fullHour = hour.getHours() + ':' + minutes + ' PM';
    }

    return fullHour;
}

export function getDayOfWeek(hour: Date) {
    return daysOfWeek[hour.getDay()];
}

export function getMonth(hour: Date) {
    return monthNames[hour.getMonth()];
}

export function getTheDate(hour: Date) {
    return hour.getDate();
}

export function getTheYear(hour: Date) {
    return hour.getFullYear();
}
