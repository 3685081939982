import React from "react";
import "./BodyText.scss";

interface IProps {
    children: any;
    isBold: boolean;
}

function BodyText({
                      children = '',
                      isBold = false
                  }: IProps) {
    return (
        <div className={isBold ? 'body-text bold' : 'body-text'}>
            {children}
        </div>
    );

}

export default BodyText;
