import React from "react";


interface IProps {
    viewMoreOnClick: ()=>void;
}

function ViewMore({viewMoreOnClick}:IProps) {
    return (
        <div className="view-more" onClick={viewMoreOnClick}>
            <div id="view-more-button">VIEW MORE</div>
        </div>
    )
}


export default ViewMore;
