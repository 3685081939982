import React from "react";
import {NavLink} from "react-router-dom";

type IProps = {
    link : string;
    title : string;
};

const SimpleButton = ({
   link = '',
   title = '',
}: IProps) => {
    return (
        <div className="view-all outline" style={{marginBottom: 30, marginTop: 30}}>
            <NavLink activeClassName={'active'} to={'/' + link}>
                {title}
            </NavLink>
        </div>
    );
};

export default SimpleButton;
