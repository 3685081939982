import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import store, {dispatchWithPromise} from "../redux";
import SetCurrentUser from "../redux/auth/actions";
import {ExtractURLReferer} from "./Parser";
// import {IUserRole} from "../types/CommonEntityTypes";

// interface IProps {
//     Component : any;
//     path : string;
//     requireLogin : boolean;
//     roles : IUserRole[];
// }

const ProtectedRoute = ({
                            Component=null,
                            requireLogin = false,
                            ...rest
                        }:any) => {

    const setComponent = (props:any)=>{
        if (
            IsAuthorised(requireLogin)
        ) {
            return <Component {...props}/>;
        }else{
            ExtractURLReferer().then();
            if(IsLoginBefore()){
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                        }}
                    />
                );
            }else{
                return (
                    <Redirect
                        to={{
                            pathname: '/register',
                        }}
                    />
                );
            }
        }
    }


    return <Route {...rest} render={setComponent} />;

};

// eslint-disable-next-line import/prefer-default-export
export { ProtectedRoute };


export function IsLoginBefore(){
    const localStoreContent = localStorage.getItem('mySeriesRunnerIsLoginBefore');
    if(
        localStoreContent !== null &&
        typeof localStoreContent === 'string' &&
        localStoreContent === 'true'
    ){
        return true;
    }else{
        return false;
    }
}
export function setIsLoginBeforeTrue(){
    localStorage.setItem('mySeriesRunnerIsLoginBefore','true');
}


export function IsAuthorised(
    requiredLogin : boolean = false,
){
    let currentUser = store.getState().auth.currentUser;

    if(currentUser.id === 0){
        const localStoreContent = localStorage.getItem('mySeriesRunnerAuth');
        if(
            localStoreContent !== null &&
            typeof localStoreContent === 'string'
        ){
            const parsedContent = JSON.parse(localStoreContent);
            dispatchWithPromise(
                SetCurrentUser(
                    parsedContent.user,
                    parsedContent.token
                )
            ).then();
            currentUser = parsedContent.user;
        }
    }


    let error = false;
    if(
        requiredLogin &&
        currentUser.id === 0
    ){
        error = true;
    }

    return !error;

}
export function IsFakeUser(){
    let currentUser = store.getState().auth.currentUser;

    return (
        currentUser.id === 0 ||
        currentUser.is_fake === 1
    );

}
