import React from "react";
import SeriesEvent from "../../../../types/Types";


interface IProps {
    display: boolean;
    event: SeriesEvent;
    selectFunction: (eventId:number)=>void;

}
const monthNames = ['January','February','March','April','May','June','July','August','September','October','November','December'];
const daysOfWeek = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];

function ListItem(
    {
        event,
        display = false,
        selectFunction = (eventId=0)=>{}
    }: IProps
) {

    const date = new Date(event.start.replaceAll('.000000Z',''));

    let isCompleted = false;
    if(date < new Date()){
        isCompleted = true;
    }

    return (
        <a onClick={()=>{
            selectFunction(event.id);
        }} className={(display)?('active'):('')}>
            <div className="left-part">
                <div className="left-part-date">
                    {daysOfWeek[date.getDay()]}, {monthNames[date.getMonth()]} {date.getDate()}, {date.getFullYear()}
                </div>
                <div className="visible">
                    {(isCompleted)?(<span className="completed-badge">Event has finished</span>):('')}
                </div>
                <div className="left-part-content">{event.title}</div>
            </div>
            <div className="middle-part">{event.location.state}</div>
        </a>
    )

}

export default ListItem;
