import React from "react";
import {Event} from "../../../utils/ServiceTypes";
import BodyText from "../../../sharedComponents/bodyText/BodyText";
import {Link} from "react-router-dom";
import BuyTicketButtonClicked from "../../../utils/PaymentHelpers";
import SimpleButton from "../../../sharedComponents/simpleButton/SimpleButton";


const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function getRightTime(hour: Date) {

    let minutes, fullHour: string;

    if (hour.getMinutes() == 0) {
        minutes = hour.getMinutes() + '0';
    } else {
        minutes = hour.getMinutes().toString();
    }
    if (hour.getHours() < 12) {
        fullHour = hour.getHours() + ':' + minutes + ' AM';
    } else {
        fullHour = hour.getHours() + ':' + minutes + ' PM';
    }

    return fullHour;
}

type IProps = {
    event : Event;
    history : any;
    buyTicketButtonFunction : (variationId:number)=>void;
    continueButtonForExternal : ()=>void;
};

const Step1BuyTicket = ({
    event,
    history,
    buyTicketButtonFunction,
    continueButtonForExternal
}:IProps)=>{
    const startHour = new Date((event !== null) ? (event.start.replaceAll('.000000Z','')) : (0));
    const endHour = new Date((event !== null) ? (event.end.replaceAll('.000000Z','')) : (0));


    const dateNow = Date.now();
    let upComingRace = true;
    if(new Date(event.start).getTime() < dateNow){
        upComingRace = false;
    }

    return(
        <React.Fragment>

            <div className={'internal-e-commerce'}>

                <div className={'info-table-field'}>
                    <div className={'left-logo-field'}>
                        <img src={event.logo} alt="Oakland Logo"/>
                    </div>
                    <div className={'right-info-field'}>
                        <div className={'time-field'}>
                            <BodyText isBold>Title:</BodyText>
                            <BodyText isBold={false}>
                                <p>
                                    ({daysOfWeek[startHour.getDay()]}, {startHour.getDate()} {monthNames[startHour.getMonth()]})
                                </p>
                                <p>
                                    {getRightTime(startHour)} - {getRightTime(endHour)}

                                </p>
                            </BodyText>

                        </div>
                        <div className={'location-field'}>
                            <BodyText isBold>Location:</BodyText>
                            <BodyText isBold={false}>{event.location.address}</BodyText>
                        </div>
                    </div>
                </div>


                {(upComingRace)?(
                    (event.are_we_selling === 1)?(
                        (event.prices.length > 0 )?(
                            <React.Fragment>

                                <div className="purchase-button-container">
                                    {event.prices.map((item)=>{
                                        return(
                                            <div className={'purchase-button'} key={'purchaseKey'+item.id}
                                                 onClick={()=>{
                                                     buyTicketButtonFunction(item.id);
                                                 }}
                                            >
                                                <div className={'title'}>
                                                    {item.title}
                                                </div>
                                                <div className={'description'}>
                                                    {item.description}
                                                </div>
                                                <div className={'price'}>
                                                    ${(Math.round(item.price * 100) / 100).toFixed(2)}
                                                </div>
                                                <div className={'buy-now'}>
                                                    Buy Now
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className={'description-area'}>

                                    <div className={'item'}>
                                        Thank you for your interest in registering for this race. Please complete your registration by purchasing your ticket using the button here. Once you submit your payment and register, your race will automatically be added to your My Series Runner account.
                                    </div>
                                    <div className={'item'}>
                                        My Series Runner is designed for runners to keep track of all your registered races, past races, enter your race results, claim your medals and search for more races using your My Series Runner membership.
                                    </div>
                                    <div className={'item'}>
                                        Don't have a My Series Runner membership yet? Please complete your profile <Link to={'/register'}>here</Link>.
                                    </div>

                                </div>

                            </React.Fragment>
                        ):(
                            <div className={'description-area'}>

                                <div className={'item'}>
                                    Sorry, your action can not be completed at the moment. No price information is assigned for this race yet. Please <a href={'mailto:info@seriesrunner.com'}>contact us</a> for more information. We apologize for the inconvenience.
                                </div>
                            </div>
                        )
                    ):(
                        (
                            (event.external_purchase_link !== null &&  event.external_purchase_link !== '')
                        )?(
                            <React.Fragment>

                                {(event.prices.length > 0)?(
                                    <div className="purchase-button-container">
                                        <div className={'purchase-button'}>
                                            <div className={'title'}>
                                                Buy Ticket
                                            </div>
                                            {(event.prices.length > 0)?(
                                                <React.Fragment>
                                                    <div className={'description'}>
                                                        {event.prices[0].description}
                                                    </div>
                                                    <div className={'price'}>
                                                        ${(Math.round(event.prices[0].price * 100) / 100).toFixed(2)}
                                                    </div>
                                                </React.Fragment>
                                            ):null}
                                            <a className={'buy-now'} href={(event.external_purchase_link)?(event.external_purchase_link):('#')} target={'_blank'}>
                                                Visit Website
                                            </a>
                                        </div>
                                    </div>
                                ):(
                                    <div className={'description-area'}>
                                        <div className={'item'}>
                                            No price information is assigned for this race yet. Please visit the race website for more information.
                                        </div>
                                        <div className={'item'}>
                                            <a href={event.external_purchase_link} target={'_blank'} className={'button'}>
                                                Visit Website
                                            </a>
                                        </div>
                                    </div>
                                )}


                                <div className={'description-area'}>

                                    <div className={'item'}>
                                        Thank you for your interest in registering for this race. Ticket sales for this event are not available through Series Runner. Please complete your registration by purchasing your ticket via the race website, using the button here. Once you submit your payment and register, you can log back in to your My Series Runner account and submit proof of payment/registration simply by uploading a screenshot or the pdf of your purchase.
                                    </div>

                                    <div className={'item'}>
                                        <div className={'button'} onClick={()=>{
                                            continueButtonForExternal();
                                        }}>
                                            Upload Registration Receipt
                                        </div>
                                    </div>

                                    <div className={'item'}>
                                        My Series Runner is designed for runners to keep track of all your registered races, past races, enter your race results, claim your medals and search for more races using your My Series Runner membership.
                                    </div>
                                    <div className={'item'}>
                                        Don't have a My Series Runner membership yet? Please complete your profile <Link to={'/register'}>here</Link>.
                                    </div>

                                </div>
                            </React.Fragment>
                        ):(
                            <React.Fragment>
                                <div className={'description-area'}>

                                    <div className={'item'}>
                                        Registration for this race is not available via this page at the moment. Please <a href={'mailto:info@seriesrunner.com'}>contact us</a> for more information.
                                    </div>

                                </div>
                            </React.Fragment>
                        )

                    )
                ):(
                    <React.Fragment>
                        <div className={'description-area'}>

                            <div className={'item'}>
                                Registration for this race is closed. If you have attended the race please upload your registration payment receipt in order to add your miles to your MySeriesRunner platform using the button below
                            </div>

                            <div className={'item'}>
                                <div className={'button'} onClick={()=>{
                                    continueButtonForExternal();
                                }}>
                                    Upload Registration Receipt
                                </div>
                            </div>

                            <div className={'item'}>
                                My Series Runner is designed for runners to keep track of all your registered races, past races, enter your race results, claim your medals and search for more races using your My Series Runner membership.
                            </div>
                            <div className={'item'}>
                                Don't have a My Series Runner membership yet? Please complete your profile <Link to={'/register'}>here</Link>.
                            </div>

                        </div>
                    </React.Fragment>
                )}




            </div>

            <SimpleButton title={'Go to My Series Runner'} link={'profile'}/>
        </React.Fragment>
    );
}

export default Step1BuyTicket;
