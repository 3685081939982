import {Event} from "./ServiceTypes";
import store, {dispatchWithPromise} from "../redux";
import {SetCurrentFavoritesWaitingAction} from "../redux/favorites/actions";
import {rejects} from "assert";
var Url = require('url-parse');

function getParameterByName(name:string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default function eventButtonParser(item: Event) {
    const parts = (item.buttons_array as string).split(',')
    const url = parts[1].split(':')
    const urlAddress = url[1] + ':' + url[2]
    return (
        urlAddress.split('"')[1]
    )
}

export function ExtractFavoriteIDs(events : Event[]):number[]{
    let ids : number[] = [];
    events.forEach((item)=>{
        ids.push(item.id);
    });
    return ids;
}

type IPromiseExtractResetPasswordToken = {
    token : string;
};
export function ExtractResetPasswordToken(){
    return new Promise<IPromiseExtractResetPasswordToken>((resolve,reject)=>{
        const token = getParameterByName('token',window.location.href);
        if(
            token !== null &&
            token !== ''
        ){
            resolve({
                token:token
            });
        }else{
            reject({});
        }
    });
}

export function ExtractURLReferer(){

    return new Promise((resolve)=>{
        const currentLocation = getParameterByName('referer',window.location.href);
        if(
            currentLocation !== null &&
            currentLocation !== ''
        ){
            let parsedJson = JSON.parse(currentLocation);
            if(
                parsedJson.origin &&
                parsedJson.origin === 'www' &&
                parsedJson.eventId &&
                parsedJson.action === 'favorite'
            ){
                dispatchWithPromise(
                    SetCurrentFavoritesWaitingAction({
                        eventId : parsedJson.eventId,
                        action : 'favorite'
                    })
                ).then(()=>{
                    resolve({});
                });
            }
        }
        resolve({});
    });
}
