import store from "../index";

export default function SetCurrentFavorites(favorites : number[]){
    return({
        type : 'SET_CURRENT_FAVORITES',
        payload : favorites
    });
}

export function SetCurrentFavoritesWaitingAction(payload : { eventId: number, action: string }){

    if(
        payload.eventId &&
        payload.action === 'favorite'
    ){
        return({
            type : 'SET_CURRENT_FAVORITES_WAITING_ACTION',
            payload : payload
        });
    }else{
        return ({type: 'UNDEFINED', payload: null});
    }
}

export function SetCurrentFavoritesWithAddition(eventId : number){
    let currentFavorites = store.getState().favorites.favorites;
    if(!currentFavorites.includes(eventId)){
        currentFavorites.push(eventId);
    }
    return({
        type : 'SET_CURRENT_FAVORITES',
        payload : currentFavorites
    });
}
export function SetCurrentFavoritesWithSubtraction(eventId : number){
    let currentFavorites = store.getState().favorites.favorites;
    const index = currentFavorites.indexOf(eventId);
    if (index > -1) {
        currentFavorites.splice(index, 1);
    }
    return({
        type : 'SET_CURRENT_FAVORITES',
        payload : currentFavorites
    });
}
