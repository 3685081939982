import React from 'react';
import './RaceButton.scss';

type IProps = {
    title: string
}

const RaceButton = ({
                        title = ''
                    }: IProps) => {

    return (
        <div className="wrapper baseline">
            <div className={'line'}>{}</div>
            <div className="title">
                {title}
            </div>
            <div className={'line'}>{}</div>
        </div>
    );
};

export default RaceButton;
