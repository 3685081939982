import React from "react";
import PageHeader from "../../sharedComponents/pageHeader/PageHeader";
import RaceButtonInside from "../raceButtonInside/RaceButtonInside";
import Button from "../../sharedComponents/buttonTheme/Button";
import {GetRegisteredEvents} from "../../utils/api/InnerPageServices";
import {Event} from "../../utils/ServiceTypes";
import LoadingIndicator from "../../sharedComponents/loading/LoadingIndicator";
import SimpleButton from "../../sharedComponents/simpleButton/SimpleButton";
import { Link } from "react-router-dom";

interface IProps {

}

interface IState {
    eventRace: Event[];
    isPending: boolean;
}

class UpcomingRaces extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            eventRace: [],
            isPending: true
        }
        this.getUpcomingRaces = this.getUpcomingRaces.bind(this);
    }

    componentDidMount() {
        this.getUpcomingRaces();
    }

    getUpcomingRaces() {
        const component = this;
        GetRegisteredEvents(true).then((result) => {
            component.setState({
                eventRace: result.events,
                isPending: false
            });
        }).catch(() => {
            component.setState({
                eventRace: [],
                isPending: false
            });
        });
    }

    render() {

        return (
            <div className={'content-body'}>
                <PageHeader
                    title={'Upcoming Races'}
                />
                {(this.state.isPending) ? (
                    <LoadingIndicator/>
                ) : (
                    (this.state.eventRace.length > 0) ? (
                        <div className="my-profile-container">
                            <div className="my-profile-row direction-column">
                                <div className="race-button-container">
                                    {this.state.eventRace.map((item: Event) => {

                                        return (
                                            <React.Fragment key={'uniqueKey' + item.id}>
                                                <RaceButtonInside
                                                    item={item}
                                                />
                                                <hr/>
                                            </React.Fragment>
                                        )

                                    })}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={'empty-list-container'}>
                            <div className={'item'}>
                                You have not registered for any races yet. List of all your Upcoming Races will be displayed here once you register for a race.
                            </div>
                            <SimpleButton title={'Find a race now'} link={'search'}/>
                        </div>
                    ))}
            </div>
        );
    }
}

export default UpcomingRaces;
