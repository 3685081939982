import React from "react";
import PageHeader from "../../sharedComponents/pageHeader/PageHeader";
import './MyProfile.scss';
import {Link, NavLink} from "react-router-dom";
import {Event, Runner} from "../../utils/ServiceTypes";
import GetProfileDetails, {GetFavoriteEvents} from "../../utils/api/InnerPageServices";
import AddAvatarPhoto from "./assets/add-photo.svg";
import LoadingIndicator from "../../sharedComponents/loading/LoadingIndicator";
import FavoritesInline from "../favorites/FavoritesInline";
import UpcomingRacesInline from "../upcomingRaces/UpcomingRacesInline";
import MedalsInline from "../medals/MedalsInline";


type IState = {
    runner: null | Runner;
    eventRace: Event[];
    isPending: boolean;
};

class MyProfile extends React.Component<any, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            runner: null,
            eventRace: [],
            isPending: true
        };

        this.getProfileDetails = this.getProfileDetails.bind(this);
        this.getUpcomingRaces = this.getUpcomingRaces.bind(this);
    }

    componentDidMount() {
        this.getProfileDetails();
        this.getUpcomingRaces();
    }

    getProfileDetails() {
        const component = this;
        GetProfileDetails().then((result) => {
            component.setState({
                runner: result,
                isPending: false
            });
        }).catch(() => {
            component.setState({
                runner: null,
                isPending: false
            });
        });
    }

    getUpcomingRaces() {
        const component = this;
        // todo : Will comment out when register part is active
        // GetRegisteredEvents().then((result) => {
        //     component.setState({
        //         eventRace: result.events,
        //         isPending: false
        //     });
        // }).catch(() => {
        //     component.setState({
        //         eventRace: [],
        //         isPending: false
        //     });
        // });
        GetFavoriteEvents().then((result)=>{
            component.setState({
                eventRace : result.events,
                isPending : false
            });
        }).catch(() => {
            component.setState({
                eventRace: [],
                isPending: false
            });
        });
    }

    render() {
        return (
            <div className={'content-body'}>
                <PageHeader
                    title={'My Profile'}
                    buttonElement={
                        <Link className={'edit-profile-button hide-mobile'} to={'/profile/edit'}>
                            Edit profile
                        </Link>
                    }
                />
                {(this.state.isPending) ? (
                    <LoadingIndicator/>
                ) : (
                    (this.state.runner !== null) ? (
                        <div className="my-profile-container">
                            <div className="my-profile-row">
                                <div className="my-profile-left">
                                    {(
                                        this.state.runner.profile &&
                                        this.state.runner.profile.charAt(this.state.runner.profile.length - 1) !== '/'
                                    )?(
                                        <div className={'image'}>
                                            <span style={{backgroundImage:'url('+this.state.runner.profile+')'}}/>
                                        </div>
                                    ):(
                                        <div style={{flex:0, width:'100%', position:'relative', overflow:'hidden', borderRadius: '50%', border: '10px solid #f2f2f2', height:'auto'}}>
                                            <Link to={'/profile/edit'}>
                                                <img src={AddAvatarPhoto} alt={'Add photo'} style={{width:'40%', margin:'30%'}}/>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                                <div className="wrapper">
                                    <div className="title">
                                        {this.state.runner.name+' '+this.state.runner.surname}{/*', 37'*/}
                                    </div>
                                    <div className="email">
                                        <a href="">{this.state.runner.email}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="my-profile-row mb-50">
                                <div className="my-profile-left tablet-hidden">
                                </div>
                                <div className="wrapper">
                                    <div className="about-me">
                                        <span>About me:</span>
                                        <p>
                                            {(
                                                this.state.runner.description !== '' &&
                                                this.state.runner.description !== null
                                            )?(
                                                this.state.runner.description
                                            ):(
                                                <Link to={'/profile/edit'} style={{color:'#000000', textDecoration:'underline'}}>
                                                    Click here to add
                                                </Link>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <UpcomingRacesInline/>

                            <MedalsInline/>

                            <FavoritesInline/>

                        </div>
                    ) : (
                        // todo :
                        null
                    )
                )}
            </div>
        );
    }
}

export default MyProfile;
