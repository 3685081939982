import React from "react";
import "./SendResultButton.scss";
import UploadIcon from './assets/upload-icon.png';

interface IProps {
    children: string;
    onClick?: ()=>void;
}

function SendResultButton({
    children = '',
    onClick = ()=>{},
}: IProps)
{
    return (
        <button className={'send-result-button'} onClick={()=>{
            onClick();
        }}>
            <div className={'send-icon'}>
            </div>
            {children}
            <div className={'send-icon'}>
                <img src={UploadIcon} alt="upload-icon"/>
            </div>
        </button>
    );

}

export default SendResultButton;
