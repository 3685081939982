import React from "react";
import "./OuterPagesLayout.scss";

import Logo from "../../sharedComponents/logo/Logo";
import Header from "../../sharedComponents/header/Header";
import BackgroundContainer from "../../sharedComponents/backgroundContainer/BackgroundContainer";
import LogoImg from '../../screens/login/assets/logo@2x.png';

interface IPropsComponent {
    children: any;
    headerTitle: string;
    bgImg: string;
}
interface IStateComponent {

}


class OuterPages extends React.Component<IPropsComponent, IStateComponent> {

    constructor(props: IPropsComponent) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className={'outer-page-body'}>

                <div className={'head-area'}>
                    <div className={'logo-area'}>
                        <Logo LogoImg={LogoImg} logoWidth={270}/>
                    </div>
                    <div className={'header-area'}>
                        <Header>{this.props.headerTitle}</Header>
                    </div>
                    {this.props.children}
                </div>
                <div className={'bg'}>
                    <BackgroundContainer img={this.props.bgImg}/>
                </div>
            </div>
        );
    }

}

export default OuterPages;
