import React from "react";
import "./GreyText.scss";

interface IProps {
    children: string;
    isBold: boolean;
}

function GreyText({
                      children = '',
                      isBold = false
                  }: IProps) {
    return (
        <div className={isBold ? 'grey-text isBold' : 'grey-text' }>

            {children}
        </div>
    );

}

export default GreyText;
