import React from "react";
import "./LoginView.scss";
import InputArea from "../../sharedComponents/inputArea/InputArea";
import OuterPages from "../../sharedComponents/outerPagesLayout/OuterPagesLayout";
import TextSmall from "../../sharedComponents/textSmall/TextSmall";
import {NavLink} from "react-router-dom";
import {LoginARunner} from "../../utils/api/OuterPageServies";
import OuterPagesButton from "../../sharedComponents/outerPagesButton/OuterPagesButton";
import store from "../../redux";
import {setIsLoginBeforeTrue} from "../../utils/ProtectedRoute";

interface IProps {
    history: {
        push: any;
    }
}

interface IState {
    email: string;
    password: string;
}

class LoginView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            email: '',
            password: ''
        };
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);

    }


    handleSubmit = (event: any) => {
        const component = this;
        event.preventDefault();

        LoginARunner(
            this.state.email,
            this.state.password
        ).then(() => {
            setIsLoginBeforeTrue();
            if(store.getState().favorites.waitingAction !== null){
                // @ts-ignore
                component.props.history.push('/event-info/'+store.getState().favorites.waitingAction?.eventId);
            }else{
                // @ts-ignore
                component.props.history.push('/profile');
            }
        }).catch((result) => {
            if(result.message){
                alert(result.message);
            }else{
                alert('An error occurred!');
            }
        });

    }


    handleEmailChange = (event: any) => {
        this.setState({
            email: event.target.value
        });
    }

    handlePasswordChange(event: any) {
        this.setState({
            password: event.target.value
        });
    }

    render() {

        return (
            <div className={'login-page'}>
                <OuterPages headerTitle={'LOG IN'} bgImg='/image/bg-signup.jpg'>
                    <div className={'form-area'}>
                        <form onSubmit={this.handleSubmit}>
                            <InputArea
                                inputType="email"
                                placeholder="Enter email address"
                                children="EMAIL"
                                inputValue={this.state.email}
                                onChangeInputValue={this.handleEmailChange}
                            />
                            <InputArea
                                inputType="password"
                                placeholder="Enter password"
                                children="PASSWORD"
                                inputValue={this.state.password}
                                onChangeInputValue={this.handlePasswordChange}
                            />
                            <OuterPagesButton blackButton type={'submit'}>LOG IN</OuterPagesButton>

                        </form>
                    </div>
                    <div className={'forgot-password'}>
                        <NavLink to={'forgot-password'} style={{textDecoration: "none"}}>
                            <TextSmall>Forgot Password</TextSmall>
                        </NavLink>

                        <hr/>
                    </div>
                    <div className={'register-area'}>
                        <div className={'register-text'}>
                            <hr/>
                            <div className={'or-text'}>
                                <TextSmall>OR</TextSmall>
                            </div>
                            <hr/>
                        </div>
                        <NavLink to={'register'}>
                            <OuterPagesButton type={'button'} blackButton={false}>SIGN UP</OuterPagesButton>
                        </NavLink>
                    </div>
                </OuterPages>
            </div>
        );
    }

}

export default LoginView;
