import React, {useEffect, useState} from "react";
import {Link, NavLink, useParams} from "react-router-dom";
import {GetEventInfo} from "../../utils/api/InnerPageServices";
import {Event} from "../../utils/ServiceTypes";
import "./BuyTicket.scss";
import SimpleButton from "../../sharedComponents/simpleButton/SimpleButton";
import LoadingIndicator from "../../sharedComponents/loading/LoadingIndicator";
import ErrorLogo from "./assets/logo.png";
import {
    RedirectPaymentWithFakeRegister,
    RedirectPrePaymentRegister
} from "../../utils/PaymentHelpers";
import Step1BuyTicket from "./steps/Step1BuyTicket";
import Step2BuyTicket from "./steps/Step2BuyTicket";
import {IReduxPart} from "../../redux";
import {connect, ConnectedProps} from "react-redux";
import Step3BuyTicket from "./steps/Step3BuyTicket";
import {
    EmptyLatestBuyTicketStep2Object,
    LatestBuyTicketStep2Object,
} from "../../redux/persisted/actions";
import Step4BuyTicket from "./steps/Step4BuyTicket";
import {IsFakeUser} from "../../utils/ProtectedRoute";





type IProps = PropsFromRedux& {
    history : any
};

const BuyTicket = (props:IProps)=>{

    const { eventId } = useParams<{ eventId: string }>();
    const [activeStep, setActiveStep] = useState<1|2|3|4>(1);
    const [event, setEvent] = useState<Event|null>(null);
    const [pending, setPending] = useState<boolean>(true);

    const [paymentObjectPartOne, setPaymentObjectPartOne] = useState<LatestBuyTicketStep2Object>(EmptyLatestBuyTicketStep2Object);

    const [showCreateUserModal, setShowCreateUserModal] = useState<boolean>(false);


    // Variation ID
    const [variationId, setVariationId] = useState<number>(0);

    useEffect(()=>{

        setPending(true);
        GetEventInfo(eventId).then((result)=>{
            setEvent(result);
            setPending(false);
        }).catch(()=>{
            setEvent(null);
            setPending(false);
        });

    },[eventId]);




    const [paymentStep, setPaymentStep] = useState<'fill'|'error'|'success'>('fill');
    const [paymentStepMessage, setPaymentStepMessage] = useState<string>('');


    if(pending){
        return (
            <div className={'buy-ticket-container'}>
                <LoadingIndicator/>
            </div>
        );
    }
    if(event === null){
        return (
            <div className={'buy-ticket-container'}>
                <img src={ErrorLogo} className={'error-icon'}/>
                <div className={'error-message'}>
                    Oops. Something went wrong. <br/>Please try again later, or <a href={'mailto:info@seriesrunner.com'}>contact us</a>.
                </div>
                <SimpleButton title={'back to my series runner'} link={'profile'}/>
            </div>
        );
    }
    const areWeSelling = (event.are_we_selling === 1);
    if(paymentStep === 'success'){
        if(areWeSelling){
            return (
                <div className={'payment-container'}>

                    <div className={'title'}>
                        <span>PAYMENT SUCCESSFUL</span> <br/>
                        {event.title}
                    </div>

                    <img src={ErrorLogo} className={'error-icon'}/>
                    <div className={'error-message'}>
                        <p>
                            Thank you for your payment. Your registration was successful. You will receive a confirmation email soon.{(!IsFakeUser())?('The race you registered for will be listed in your account on My Series Runner membership portal.'):''}
                        </p>
                        {(IsFakeUser())?(
                            <React.Fragment>
                                <p>
                                    Don't have a My Series Runner membership account yet?
                                </p>
                                <p>
                                    Please click here to <Link to={'/register-complete'}>sign up</Link> to access all your registered races. For more information please <a href={'mailto:info@seriesrunner.com'}>contact us</a>.
                                </p>
                            </React.Fragment>
                        ):null}
                    </div>
                    {(!IsFakeUser())?(
                        <SimpleButton title={'back to My Series Runner'} link={'search'}/>
                    ):(
                        <div className="view-all outline" style={{marginBottom: 30, marginTop: 30}}>
                            <a href={'https://www.seriesrunner.com'}>
                                Back to Series Runner
                            </a>
                        </div>
                    )}
                </div>
            );
        }else{
            return (
                <div className={'payment-container'}>

                    <div className={'title'}>
                        <span>SUCCESSFUL UPLOAD</span> <br/>
                        {event.title}
                    </div>

                    <img src={ErrorLogo} className={'error-icon'}/>
                    <div className={'error-message'}>
                        <p>
                            Thank you for your submission. Your receipt was uploaded successfully.  The race you registered for will be listed in your account on My Series Runner membership portal when it is approved.
                        </p>

                        <p>
                            For more information please <a href={'mailto:info@seriesrunner.com'}>contact us</a>.
                        </p>
                    </div>
                    <SimpleButton title={'back to search'} link={'search'}/>
                </div>
            );
        }
    }
    return (
        <div className={'buy-ticket-container'}>

            <div className={'top'}>
                {event.title}
            </div>

            <div className={'steps-holder'}>
                <div className="wrapper">
                    <div className={(activeStep === 1)?'step active':'step'}>
                        <span>1</span>
                        {(areWeSelling)?<span>Select Your Ticket</span>:<span>Purchase Your Ticket</span>}
                    </div>

                    {(areWeSelling)?(
                        <div className={(activeStep === 2)?'step active':'step'}>
                            <span>2</span>
                            <span>Enter details</span>
                        </div>
                    ):null}
                    {(areWeSelling)?(
                        <div className={(activeStep === 3)?'step active':'step'}>
                            <span>3</span>
                            <span>Confirm and pay</span>
                        </div>
                    ):null}

                    {(areWeSelling)?null:(
                        <div className={(activeStep === 4)?'step active':'step'}>
                            <span>2</span>
                            <span>Upload Your Registration Receipt</span>
                        </div>
                    )}
                </div>
            </div>

            {(activeStep === 1)?(
                <Step1BuyTicket
                    event={event}
                    history={props.history}
                    buyTicketButtonFunction={(variationId:number)=>{
                        if(props.auth.currentUser.id !== 0){
                            setVariationId(variationId);
                            setActiveStep(2);
                            window.scrollTo({top:0,left:0,behavior:'smooth'});
                        }else{
                            setVariationId(variationId);
                            setShowCreateUserModal(true);
                        }
                    }}
                    continueButtonForExternal={()=>{
                        if(
                            event !== null &&
                            event.are_we_selling === 0
                        ){
                            if(props.auth.currentUser.is_fake === 0){
                                setActiveStep(4);
                            }else{
                                setShowCreateUserModal(true);
                            }
                        }
                    }}
                />
            ):null}

            {(activeStep === 2)?(
                <Step2BuyTicket
                    event={event}
                    variation={variationId}
                    history={props.history}
                    latestBuyTicketStep2Object={props.persisted.latestBuyTicketStep2Object}
                    continueButtonFunction={(object:LatestBuyTicketStep2Object)=>{
                        setPaymentObjectPartOne(object);
                        setActiveStep(3);
                        window.scrollTo({top:0,left:0,behavior:'smooth'});
                    }}
                    backButtonFunction={()=>{
                        setVariationId(0);
                        setActiveStep(1);
                        window.scrollTo({top:0,left:0,behavior:'smooth'});
                    }}
                />
            ):null}

            {(activeStep === 3)?(
                <Step3BuyTicket
                    event={event}
                    variation={variationId}
                    history={props.history}
                    paymentObjectPartOne={paymentObjectPartOne}
                    continueButtonFunction={(status:any, message:string)=>{
                        if(status === 'success'){
                            setPaymentStepMessage(message);
                            setPaymentStep('success');
                        }
                    }}
                    backButtonFunction={()=>{
                        setActiveStep(2);
                        window.scrollTo({top:0,left:0,behavior:'smooth'});
                    }}
                />
            ):null}

            {(activeStep === 4)?(
                <Step4BuyTicket
                    event={event}
                    history={props.history}
                    continueButtonFunction={(status:any, message:string)=>{
                        if(status === 'success'){
                            setPaymentStepMessage(message);
                            setPaymentStep('success');
                        }
                    }}
                    backButtonFunction={()=>{
                        setActiveStep(1);
                        window.scrollTo({top:0,left:0,behavior:'smooth'});
                    }}
                />
            ):null}

            <div className={(showCreateUserModal)?('create-user-modal active'):('create-user-modal')} onClick={()=>{
                setShowCreateUserModal(false);
            }}>

                <div className="view-all outline">
                    <div onClick={(event)=>{{
                        event.stopPropagation();
                        if(areWeSelling){
                            RedirectPaymentWithFakeRegister().then(()=>{
                                setActiveStep(2);
                                setShowCreateUserModal(false);
                                window.scrollTo({top:0,left:0,behavior:'smooth'});
                            }).catch(()=>{
                                setShowCreateUserModal(false);
                                window.scrollTo({top:0,left:0,behavior:'smooth'});
                            });
                        }else{
                            setShowCreateUserModal(false);
                        }
                    }}}>
                        {(areWeSelling)?(
                            'Complete without registering'
                        ):(
                            'Cancel'
                        )}
                    </div>
                </div>
                <span style={{marginBottom: 30, marginTop: 30}}>OR</span>
                <div className="view-all outline special">
                    <div onClick={(event)=>{{
                        event.stopPropagation();
                        setShowCreateUserModal(false);
                        window.scrollTo({top:0,left:0,behavior:'smooth'});
                        RedirectPrePaymentRegister(eventId,props.history);
                    }}}>
                        {(areWeSelling)?(
                            'register now to submit payment'
                        ):(
                            'register now to submit receipt'
                        )}
                    </div>
                </div>

            </div>

        </div>
    );

}

function mapStateToProps(state:IReduxPart){
    return{
        auth : state.auth,
        persisted : state.persisted
    }
}
const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(BuyTicket);
