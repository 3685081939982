import React from "react";
import "./ResetPassword.scss";
import InputArea from "../../sharedComponents/inputArea/InputArea";
import OuterPages from "../../sharedComponents/outerPagesLayout/OuterPagesLayout";
import OuterPagesButton from "../../sharedComponents/outerPagesButton/OuterPagesButton";
import {ExtractResetPasswordToken} from "../../utils/Parser";
import {SendResetPasswordRequest} from "../../utils/api/OuterPageServies";
import {NavLink} from "react-router-dom";
import TextSmall from "../../sharedComponents/textSmall/TextSmall";



interface IProps {
    history: {
        push: any;
    }
}
interface IState {
    email: string;
    password: string;
    confirmPassword: string;
    token: string;
}

class ResetPassword extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            email: '',
            token: '',
            password: '',
            confirmPassword: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    }

    componentDidMount() {
        const component = this;
        ExtractResetPasswordToken().then((result)=>{
            if(result && result.token && result.token !== ''){
                component.setState({
                    token : result.token
                });
            }else{
                component.props.history.push('/');
            }
        }).catch(()=>{
            component.props.history.push('/');
        });
    }

    handleSubmit = (event:any) => {
        event.preventDefault();
        const component = this;
        SendResetPasswordRequest(
            this.state.email,
            this.state.password,
            this.state.confirmPassword,
            this.state.token,
        ).then((result:any)=>{
            alert(result.message);
            component.props.history.push('/login');
        }).catch((result)=>{
            if(result && result.message){
                alert(result.message);
            }else{
                alert('An error occurred!');
            }
        });
    }

    handleEmailChange(event:any) {
        this.setState({
            email: event.target.value
        });
    }


    handlePasswordChange(event:any) {
        this.setState({
            password: event.target.value
        });
    }

    handleConfirmPasswordChange(event:any) {
        this.setState({
            confirmPassword: event.target.value
        });
    }
    render() {
        return (
            <div className={'reset-password'}>
                <OuterPages headerTitle={'RESET PASSWORD'} bgImg='/image/bg-forgotpass.jpg'>
                    <div className={'form-area'}>
                        <form onSubmit={this.handleSubmit}>
                            <InputArea
                                inputType="text"
                                placeholder="Enter email address"
                                children="EMAIL"
                                inputValue={this.state.email}
                                onChangeInputValue={this.handleEmailChange}
                            />
                            <InputArea
                                inputType="password"
                                placeholder="Enter password"
                                children="PASSWORD"
                                inputValue={this.state.password}
                                onChangeInputValue={this.handlePasswordChange}
                            />
                            <InputArea
                                inputType="password"
                                placeholder="Re-enter password"
                                children="CONFIRM PASSWORD"
                                inputValue={this.state.confirmPassword}
                                onChangeInputValue={this.handleConfirmPasswordChange}
                            />
                            <OuterPagesButton blackButton type={'submit'}>SAVE</OuterPagesButton>
                        </form>

                    </div>
                    <div className={'back-to-sign'} style={{marginTop:30}}>
                        <NavLink to={'login'} style={{textDecoration: "none", color: '#000000'}}>
                            <TextSmall>Back to log in</TextSmall>
                            <hr/>
                        </NavLink>

                    </div>
                </OuterPages>
            </div>
        );
    }

}

export default ResetPassword;
