import React, {useEffect} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import LoginView from "./screens/login/LoginView";
import {IsAuthorised, IsFakeUser, ProtectedRoute} from "./utils/ProtectedRoute";
import SideMenu from "./sharedComponents/sideMenu/SideMenu";
import ForgotPassword from './screens/forgotPassword/ForgotPassword';
import Register from './screens/register/Register';
import ResetPassword from './screens/resetPassword/ResetPassword';
import ProfileNavigationView from "./views/ProfileNavigationView";
import MedalsView from './screens/medals/MedalsView';
import ResultsNavigationView from "./views/ResultsNavigationView";
import UpcomingRacesNavigationView from './views/UpcomingRacesNavigationView';
import FavoritesNavigationView from './views/FavoritesNavigationView';
import ScrollToTop from "./sharedComponents/scrollToTop/ScrollToTop";
import SearchRaces from './screens/searchRaces/SearchRaces';
import EventInfoInside from "./sharedComponents/eventInfoInside/EventInfoInside";
import BuyTicket from "./screens/buy-ticket/BuyTicket";
// import Payment from "./screens/payment/Payment";
// import PrePayment from "./screens/payment/PrePayment";
import CompleteRegister from "./screens/register/CompleteRegister";


const ROUTES_THAT_SIDE_MENU_IS_AVAILABLE = [
    '/home',
    '/profile',
    '/results',
    '/upcoming-races',
    '/favorites',
    '/medals',
    '/payment',
    '/event-info',
    '/search',
    '/register-complete'
];

function App() {

    const isAuth = IsAuthorised();

    return (
        <Router>
            <ScrollToTop/>
            <div className="site-wrapper">

                <Switch>
                    <ProtectedRoute
                        path={ROUTES_THAT_SIDE_MENU_IS_AVAILABLE}
                        Component={SideMenu}
                        requireLogin={true}
                        roles={[0, 1]}
                    />
                </Switch>
                <Switch>

                    {/*<ProtectedRoute*/}
                    {/*    path={'/home'}*/}
                    {/*    Component={HomeView}*/}
                    {/*    requireLogin={true}*/}
                    {/*/>*/}

                    <ProtectedRoute
                        path={'/profile'}
                        Component={ProfileNavigationView}
                        requireLogin={true}
                    />

                    <ProtectedRoute
                        path={'/results'}
                        Component={ResultsNavigationView}
                        requireLogin={true}
                    />
                    <ProtectedRoute
                        path={'/upcoming-races'}
                        Component={UpcomingRacesNavigationView}
                        requireLogin={true}
                    />

                    <ProtectedRoute
                        path={'/favorites'}
                        Component={FavoritesNavigationView}
                        requireLogin={true}
                    />
                    <ProtectedRoute
                        path={'/search'}
                        Component={SearchRaces}
                        requireLogin={true}
                    />

                    {(IsFakeUser())?(
                        <ProtectedRoute
                            path={'/register-complete'}
                            Component={CompleteRegister}
                            requireLogin={true}
                        />
                    ):null}

                    <Route
                        path="/buy-ticket/:eventId"
                        render={(props) => <BuyTicket {...props} />}
                    />
                    {/*<Route*/}
                    {/*    path="/pre-payment/:eventId"*/}
                    {/*    render={(props) => <PrePayment {...props} />}*/}
                    {/*/>*/}
                    {/*<ProtectedRoute*/}
                    {/*    path={'/payment/:eventId'}*/}
                    {/*    Component={Payment}*/}
                    {/*    requireLogin={true}*/}
                    {/*/>*/}

                    <Route
                        path="/login"
                        render={(props) => <LoginView {...props} />}
                    />
                    <Route
                        path="/forgot-password"
                        render={(props) => <ForgotPassword {...props} />}
                    />
                    <Route
                        path="/register"
                        render={(props) => <Register {...props} />}
                    />
                    <Route
                        path="/reset-password"
                        render={(props) => <ResetPassword {...props} />}
                    />
                    <ProtectedRoute
                        path="/medals"
                        Component={MedalsView}
                        requireLogin={true}
                    />
                    <ProtectedRoute
                        path="/event-info/:eventId"
                        Component={EventInfoInside}
                        requireLogin={true}
                    />

                    <Redirect to={(!IsFakeUser())?('/profile'):('/register-complete')}/>
                </Switch>

            </div>
        </Router>
    );
}

export default App;
