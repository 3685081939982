import React, {useEffect, useState} from "react";
import {GetFavoriteEvents, GetRunnerMedals} from "../../utils/api/InnerPageServices";
import RaceButton from "../raceButton/RaceButton";
import LoadingIndicator from "../../sharedComponents/loading/LoadingIndicator";
import {Event, Medal} from "../../utils/ServiceTypes";
import RaceButtonInside from "../raceButtonInside/RaceButtonInside";
import SimpleButton from "../../sharedComponents/simpleButton/SimpleButton";
import {NavLink} from "react-router-dom";
import MedalAccordion from "../../sharedComponents/medalAccordion/MedalAccordion";

type IProps = {
    link?: string;
}

const ViewAllButton = ({
                           link = '',
                       }: IProps) => {
    return (
        <div className="view-all" style={{marginBottom: 60}}>
            <NavLink activeClassName={'active'} to={'/' + link}>
                VIEW ALL
            </NavLink>
        </div>
    );
};

const MedalsInline = ()=>{

    const [isPending,setIsPending] = useState<boolean>(true);
    const [medals,setMedals] = useState<Medal[]>([]);

    useEffect(()=>{
        GetRunnerMedals().then((result)=>{
            setMedals(result.medals);
            setIsPending(false);
        }).catch(()=>{
            setMedals([]);
            setIsPending(false);
        })
    },[]);

    return(
        <div className="my-profile-row direction-column">
            <div className="race-button-container">

                <RaceButton
                    title={'Medals'}
                />
                {(isPending) ? (
                    <LoadingIndicator/>
                ) : (
                    (medals.length > 0) ? (
                        <React.Fragment>
                            {medals.slice(0,3).map((item) => {
                                return(
                                    <MedalAccordion
                                        key={'unique'+item.year}
                                        medal={item}
                                    />
                                );
                            })}
                            <ViewAllButton link={'medals'}/>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <h3 className={'warningMessage'}>You have no medals.</h3>
                            <SimpleButton title={'Find a Race Now'} link={'search'}/>
                        </React.Fragment>
                    ))}
                <br/>
            </div>
        </div>
    );

}

export default MedalsInline;
