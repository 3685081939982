import React, {useState} from "react";
import {ChangePassword} from "../../utils/api/InnerPageServices";

const PasswordChanger = ()=>{

    const [pass, setPass] = useState('');
    const [passConfirm, setPassConfirm] = useState('');
    const [disableButton, setDisableButton] = useState(false);

    return(
        <React.Fragment>
            <div className={'row'}>
                <div className="inline-title">
                    CHANGE PASSWORD
                </div>
            </div>
            <div className={'row'}>
                <label htmlFor="password">
                    <input type="password" id={'password'} value={pass} placeholder={'Enter new password'}
                           onChange={(event) => {
                               setPass(event.target.value);
                           }}/>
                </label>
                <label htmlFor="passwordConfirm">
                    <input type="password" id={'passwordConfirm'} value={passConfirm} placeholder={'Re-enter new password'}
                           onChange={(event) => {
                               setPassConfirm(event.target.value);
                           }}
                    />
                </label>
            </div>
            <div className={'row'}>
                <div className={(disableButton)?('change-pass-button disabled'):('change-pass-button')} onClick={()=>{
                    if(disableButton){
                        return;
                    }
                    if(pass !== passConfirm){
                        alert('The passwords you entered do not match!');
                    }else{
                        setDisableButton(true);
                        ChangePassword(pass).then((result)=>{
                            setDisableButton(false);
                            if(result.success){
                                setPass('');
                                setPassConfirm('');
                                alert('Your password has been successfully changed.');
                            }else{
                                alert('An error was encountered while changing your password. Please try again later.');
                            }
                        }).catch(()=>{
                            setDisableButton(false);
                            alert('An error was encountered while changing your password. Please try again later.');
                        });
                    }
                }}>
                    CHANGE PASSWORD
                </div>
            </div>
        </React.Fragment>
    );
}

export default PasswordChanger;
