import SeriesEvent, {ListEventMapContent, ListEventRenderContent} from "../types/Types";
import store from "../../../redux";

const monthNames = ['January','February','March','April','May','June','July','August','September','October','November','December'];

export function searchEventsForCalendar(events:SeriesEvent[], groupType : 'region' | 'date'|'search', searchInput : string = ''):SeriesEvent[]{
    if(groupType === 'search'){

        let suitables : SeriesEvent[] = [];

        events = events.filter((event)=>{
            return(
                !(
                    event.title.toLowerCase().search(searchInput.toLowerCase()) == -1 &&
                    event.location.state.toLowerCase().search(searchInput.toLowerCase()) == -1 &&
                    event.location.address.toLowerCase().search(searchInput.toLowerCase()) == -1
                )
            );
        });

        events.forEach((event)=>{
            if(!event.active){
                return null;
            }
            suitables.push(event);

        });

        return suitables;

    }else{
        let suitables : SeriesEvent[] = [];
        events.forEach((event)=>{
            if(!event.active){
                return null;
            }
            suitables.push(event);

        });

        return suitables;
    }
}
export function groupEventsForMap(events:SeriesEvent[], groupType : 'region' | 'date'|'search', searchInput : string = ''):ListEventMapContent[]{
    let suitables : SeriesEvent[] = [];
    if(groupType === 'search'){


        events = events.filter((event)=>{
            return(
                !(
                    event.title.toLowerCase().search(searchInput.toLowerCase()) == -1 &&
                    event.location.state.toLowerCase().search(searchInput.toLowerCase()) == -1 &&
                    event.location.address.toLowerCase().search(searchInput.toLowerCase()) == -1
                )
            );
        });

        events.forEach((event)=>{
            if(!event.active){
                return null;
            }
            suitables.push(event);

        });


    }else{
        events.forEach((event)=>{
            if(!event.active){
                return null;
            }
            suitables.push(event);

        });
    }

    let locationObject : any = {};
    suitables.forEach((event)=>{
        if(locationObject[event.location_id]){
            locationObject[event.location_id].events.push(event);
        }else{
            locationObject[event.location_id] = {
                location_id : event.location_id,
                location : event.location,
                events : [event]
            };
        }
    });

    let renderArray : ListEventMapContent[] = [];
    Object.keys(locationObject).map((key, index) => {
        let temp = locationObject[key];
        let events = temp.events;

        events.sort((a:SeriesEvent,b:SeriesEvent)=>{
            return (new Date(a.start).getTime() - new Date(b.start).getTime());
        });

        temp.events = events;

        renderArray.push(temp);

    });

    return renderArray;

}

export function groupEvents(events:SeriesEvent[], groupType : 'region' | 'date'|'search', searchInput : string = '') : ListEventRenderContent[] {

    if(groupType === 'region'){

        let filteredObject : any = {};
        let regionArray : string[] = [];

        events.forEach((event)=>{
            if(!event.active){
                return null;
            }

            let region = event.location.state;

            if(!(region in filteredObject)){
                filteredObject[region] = [];
                regionArray.push(region);
            }
            filteredObject[region].push(event);

        });

        let renderArray : ListEventRenderContent[] = [];
        // eslint-disable-next-line
        Object.keys(filteredObject).map((key, index) => {

            const region = filteredObject[key];

            renderArray.push({
                title : key,
                monthAndYearNumber : 0,
                events : region
            });

        });


        return renderArray;

    }else if(groupType === 'search'){

        let filteredObject : any = {};
        let suitables : SeriesEvent[] = [];

        events = events.filter((event)=>{
            return(
                !(
                    event.title.toLowerCase().search(searchInput.toLowerCase()) == -1 &&
                    event.location.state.toLowerCase().search(searchInput.toLowerCase()) == -1 &&
                    event.location.address.toLowerCase().search(searchInput.toLowerCase()) == -1
                )
            );
        });

        events.forEach((event)=>{
            if(!event.active){
                return null;
            }
            suitables.push(event);

        });

        let renderArray : ListEventRenderContent[] = [];
        // eslint-disable-next-line
        renderArray.push({
            title : 'Search Term: '+searchInput.toUpperCase(),
            monthAndYearNumber : 0,
            events : suitables
        });


        return renderArray;

    }else{

        let filteredObject : any = {};
        let yearsArray : number[] = [];
        let monthsArray : number[] = [];

        events.forEach((event)=>{
            if(!event.active){
                return null;
            }

            let dateObject = new Date(event.start);

            let year = dateObject.getFullYear();
            let month = dateObject.getMonth();

            if(!(year in filteredObject)){
                filteredObject[year] = {};
                yearsArray.push(year);
            }
            if(!(month in filteredObject[year])){
                filteredObject[year][month] = [];
                monthsArray.push(month);
            }

            filteredObject[year][month].push(event);

        });

        let renderArray : ListEventRenderContent[] = [];

        // eslint-disable-next-line
        Object.keys(filteredObject).map((key, index) => {

            const year = filteredObject[key];

            // eslint-disable-next-line
            Object.keys(year).map((key2, index2) => {

                const month = year[key2];

                let monthString = (key2.length < 2)?('0'+key2):(''+key2);
                let monthAndYear = ''+key+monthString

                month.sort((a:SeriesEvent,b:SeriesEvent)=>{
                    return(new Date(a.start).getTime() - new Date(b.start).getTime());
                });

                renderArray.push({
                    title : monthNames[parseInt(key2)] + ' ' + key,
                    monthAndYearNumber : parseInt(monthAndYear),
                    events : month
                });

            });

        });

        renderArray.sort((a,b)=>{
            return (a.monthAndYearNumber - b.monthAndYearNumber);
        });

        return renderArray;

    }

}
