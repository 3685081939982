import React from "react";
import "./TextInput.scss"
import ArrowIcon from "./assets/arrow.png";
import ActiveArrowIcon from "./assets/yellow-arrow.png";

type IProps = {
    label:string;
    placeholder:string;
    inputValue:string;
    onChange: (value:string)=>void;
    disabled?:boolean;
    values:{title:string,value:string}[];
};

const SelectInput = ({
    label = '',
    inputValue = '',
    placeholder = '',
    disabled = false,
    onChange = (value:string)=>{},
    values=[]
}:IProps)=>{

    return(
        <React.Fragment>
            {(label !== '')?(
                <div className={'custom-text-input-label'}>
                    {label}
                </div>
            ):null}
            <div className={'custom-text-input'}>
                <select
                    value={inputValue}
                    onChange={(event)=>{
                        onChange(event.target.value);
                    }}
                    disabled={disabled}
                    placeholder={placeholder}
                >
                    {values.map((item)=>{
                        return(
                            <option value={item.value} key={'unique'+item.value.replaceAll(' ','')}>
                                {item.title}
                            </option>
                        );
                    })}
                </select>
                <img src={ArrowIcon} className={'arrow'}/>
                <img src={ActiveArrowIcon} className={'arrow-active'}/>
            </div>

        </React.Fragment>
    );

}

export default SelectInput;
